// Deine footer-Variable
const footer = ``;

// Dein Tip-Array mit der aktualisierten "enable"-Eigenschaft für die Checkbox
const tips = [
  {
    tipId: 'newFeatures5',
    title: 'Update xx.xx.2024',
    contentText:
    'Stand: xx.xx.2024<br><br>'+
    '#Neue Funktionen<br>'
    +'<li>Zahlungserinnerung, 1. - 3. Mahnung Erstellung<br>'
    +'<li>Volle Shopify Integration mit Bestand(bidirektional), Bestellung und zugehörigen Kunden import<br><br>'+

    '#Fehlerbehebungen<br>'+
    '- .<br>',

    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer,
  },
  {
    tipId: 'newFeatures4',
    title: 'Update 10.11.2024',
    contentText:
    'Stand: 10.11.2024<br><br>'+
    '#Neue Funktionen<ul>'
    +'<li>Anzeige des Kontostands beim bezahlen, zur schnelleren Entscheidung ob ggfls. vom Kontoguthaben bezahlt wird<br></li>'
    +'<li>Shopify Integration ist nun vorhanden<br></li>'
    +'<li>Artikelbezeichnung im Katalog lässt nun mehr Zeichen zu, und Schrift wird je nach Zeichenlänge angepasst bis 70 Zeichen. <br>Maximal Zulässig 70 Zeichen für den Katalog, ab 70 Zeichen wird abgeschnitten.<br></li>'
    +'<li>Artikelbezeichnung im Katalog druckt falls ein Alias für einen Artikel vorhanden ist diesen sinst Artikelbezeichnung<br>'
    +'<li>Der Menüpunkt >Kontake/Kunden/Lieferanten wurde in >Adressen & Verwaltung umbenannt.</li>'
    +'<li>Setzen von Standard-Werten wie Zahlungsziel, Skonto, Skontoziel in den Einstellungen.</li>'
    +'<li>Sicherheitsüberprüfung beim Anlegen von Kunden, auf bereits existierende Emailadressen.</li>'
    +'<li>Der Menüpunkt >Kontake/Kunden/Lieferanten wurde in >Adressen & Verwaltung umbenannt.</li>'
    +'<li>Es wurde bei Auftrag eine Rasteransicht, mit Vollbild für z.b. Vertriebsmitarbeiter eingefügt</li>'
    +'<li>Zahlungen für Kunden können direkt nun über die Listenansicht gebucht werden.</li>'
    +'<li>Passwort zurücksetzen für Kunden können direkt nun über die Listenansicht gesendet werden.</li>'
    +'<li>Einstellmäglichkeit von Texten für Zahlungserinnerung, 1. Mahnung bis 3.Mahnung</li>'
    +'<li>Einstellmäglichkeit von automatichen Versand,Bezahl-QRCode für Zahlungserinnerung, 1. Mahnung bis 3.Mahnung</li>'
    +'<li>Es kann nun eine maximale Kreditbetrag für Kunden eingestellt werden</li>'
    +'<li>Es kann nun eingestellt werden ob Verkaufspreise auf Lieferscheinen für Kunden angezeigt werden.</li>'
    +'<li>Die Gültigkeit von Angeboten kann nun angegeben werden.</li>'+

    '</ul>'+
    '#Perfomance<ul>'
    +'<li>Es wurden einige Anpassungen gemacht um die Perfomance zu steigern.</li>'
    +'<li>Famtura kann nun installiert und als App genutzt werden.</li>'+
    '<br></ul>'+
    '#Fehlerbehebungen<ul>'+
    '<li>Fehler beim erstellen der PDF in Angebot wurde behoben.</li>'+
    '<li>Kleinere beim erstellen eines neuen Accounts wurden behoben.</li>'+
    '<li>Fehler Seitenumbruch im PDF bei Geschäftsschreiben behoben.</li>'+
    '<li>Fehler in der mobilen Ansicht wurden behoben.<br>'+
    '</ul>'+
    '#Änderungen<ul>'+
    '<li>Das Emailfeld wurde bei Adressen&Verwaltung an die erste Stele vorgerückt.</li>'+
    '</ul>',

    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer,
  },
  {
    tipId: 'newFeatures3',
    title: 'Update 22.10.2024',
    contentText:
    'Stand: 22.10.2024<br><br>'+
    '#Neue Funktionen für die Rechnungs, Angebot, Auftrag, Gutschrift - Erstellung<br>'
    +'<li>Sicht des letzten Verkaufspreises des Artikel an den jeweiligen Kunden bei betätigen der Position.</li>'
    +'<li>Useability Verbesserungen.</li>'
    +'<li>in Tickets ist es möglich Bilder einzufügen</li>'
    +'<li>Ungelesene Ticket-Antworten werden mit Anzahl unten links nun erkennbar gemacht und gezeigt.</li>'+
    +'<li>Geschäftsbriefe hat nun die Möglichkeit "Speichern unter" und Bezeichnung ändern.</li>'+
    +'<li>Ein Rabattrechner für den festen Betrag zu Prozent wurde im Katalog eingefügt.</li>'+
    +'<li>Die Angabe des Rabatt auf die Endsumme zusätzlich zu den Artikeln ist nun auch mit prozentualer Angabe möglich.</li>'+
    +'<li>Versenden des Katalogs per Email ist nun allgemein auch möglich</li>'+
    +'<li>Versenden des Katalogs per Email ist nun in den jeweiligen Dokumenten auch möglich</li>'+
    +'<li>Charge Verfolgung wurde bei den Artikeln hinzugefügt</li>'+
    +'<li>Die Möglichkeit für das ausschalten der Beschreibung(ink.EAN) ist nun bei den Kundeneinstellungen direkt möglich.</li>'+
    +'<li>Es ist nun möglich direkt aus einem Angebot, eine Rechnung zu erstellen.</li>'+
    +'<li>Es ist nun möglich direkt aus dem aufgerufenen Auftrag nicht nur in der Listenansicht, aus einem Auftrag eine Rechnung zu erstellen.</lir>'+
    +'<li>Einige Arbeiten zu weietren erhöhung der Perfomance</li>'+
    +'<li>Shopify Integration ist vorbereitet</li>'+

    '</ul>#Fehlerbehebungen<br><ul>'+
    '<li>beim scannen des Barcodes auf mobilen Endgeräten wurden kleinere Fehler behoben.</li>'+
    '<li>Das ausblenden der Tips, wurde beim löschen des Cache wiederhergestellt, diese wurde behoben.</li>'+
    '<li>Einfügen von längeren Texten im Geschäftsbrief sorgte für eine Verzögerung, wurde behoben.</li>'+
    '<li>Kleinere Din 5008 korrekturen wurden getätigt .</li>'+
    '<li>Fehler bei der Preisdarstellung bei verknüpften Artikel wir z.B. bei Pfand oder Arbeitsleistung, bei Listenauswahl wurde korrigiert.</li>'+
    '<li>Kleinere Korrekturen in Deutschen Währungen.</li>'+
    '<li>Kleinere Korrekturen in Deutschen Währungen.</li>',

    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer,
  },
  {
    tipId: 'newFeatures2',
    title: 'Neue Features',
    contentText:
    'Stand: 27.09.2024<br><br>'+
    '#Neue Funktionen für die Rechnungs, Angebot, Auftrag, Gutschrift - Erstellung<br>'
    +'<li>Gutschrift Modul hinzugefügt.<br>'
    +'<li>Unterschrift und Stempel ließen sich bisher nur global in den Einstellungen setzen, Diese Einstellung dient nun als Standardwert, welcher für jedes Dokument jeweils ein und ausgeschaltet werden kann.<br>'+
    '#Neue Funktionen für die Tickets.<br>'
    +'<li>Ungelesene Ticket sind erkennbar durch einen roten Punkt und Fetter hervorgehoben.<br>'
    +'<li>Ungelesene Ticket-Antworten werden mit Anzahl unten links nun erkennbar gemacht und gezeigt.<br><br>'+
    '#Fehlerbehebungen<br>'+
    '- in der Dartstellung auf mobilen Endgeräten wurden mehrere Fehler behoben.<br>'+
    '- Fehler beim ausloggen, wurden behoben.<br>'+
    '- Um bei einer großen Anzahl von Artikel unnötige Ladezeit zu vermeiden, wurden für den Fall das versehentlich in der Listenauswahl alle Artikel ausgewählt wurden, die Anzahl der Artikel auf 300 begrenzt.<br>',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer,
  },
  {
    tipId: 'newFeatures1',
    title: 'Neue Features',
    contentText: 
    'Stand: 19.09.2024<br><br>'+
    '#Neue Funktionen für Katalog.<br>- Rabattierte Preise können rot markiert werden in der Katalog-Ansicht.<br>- Rabattierte Preise werden im Katalog angezeigt<br><br>'+
    '#Neue Funktionen in den Einstellungen->Erweitert.<br>'
    +'<li>Benutzerdefiniertes Feld, kann nach belieben benannt werden, welches auf der rechten Seite auf den Dokumenten im Bereich von Datum, Bearbeiter usw. erscheint. Bsp. GLB-Nummer: 23323 (erlaubte Gesamtlänge inkl. Feldbezeichnung 23 Zeichen)<br><br>'+
    '#Neue Funktionen für die Artikelverwaltung im Katalog.<br>'
    +'<li>Eintrag eines Suchtags für das suchen oder gruppierte suchen der Artikel, definierbar.<br><br>'+
    '#Neue Funktionen für die Rechnungs, Angebot, Auftrag, Gutschrift - Erstellung<br>'
    +'<li>Benutzerdefiniertes Feld ist ausfüllbar und erscheint wenn es einen Inhalt hat, auf den Dokumenten.<br>'
    +'<li>Auswahl des Kunden anhand der Suchtags möglich.<br>'
    +'<li>Auswahl der Artikel anhand der Suchtags möglich auch in der Listenauswahl, wenn mehrere Artikel mit dem selben tag versehen sind, erscheinen alle welche diesen tag enthalten, dadurch kann auch eine art gruppieren von Artikel gemacht werden.<br><br>'+
    '#Neue Funktionen für die Kundenverwaltung.<br>'
    +'<li>Benutzerdefiniertes Feld ist für jede Lieferadresse seperat, definierbar.<br><br>',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer,
  },
  {
    tipId: 'erweiterteAnsichtAuswahl',
    title: 'Weitere Eigenschaften aktivieren/Deaktiveren',
    contentText: 'Hier können Sie weitere Eigenschaften aktivieren oder deaktivieren. Wie z.B. die Erweiterte Ansicht, Alias, Öffentlich..',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer,
  },
  {
    tipId: 'contactType',
    title: 'Kontakt Art',
    contentText: 'Wählen Sie hier die Art des Kontakts aus. z.B. Kunde, Lieferant, Kontakt.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, 
  },
  {
    tipId: 'vkPreis',
    title: 'Verkauspreis',
    contentText: 'Gehen Sie mit der Maus über die Bezeich VK-Preis(Verkaufspreis), um verschiedene Preise einzugeben oder zu sehen. Klicken Sie dazu jeweils auf VK-1 oder VK-2...',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, 
  },
  {
    tipId: 'orderToInvoiceTip',
    title: 'Auftrag -> Rechnung',
    contentText: 'Hier können Sie aus einer oder mehrere Aufträgen, des selben Empfängers, zusammenfassend oder einzeln eine Rechnung erstelen lassen.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, 
  },
  {
    tipId: 'logOutTip',
    title: 'Abmelden',
    contentText: 'Hier können Sie sich abmelden.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, 
  },
  {
    tipId: 'deleteUserTip',
    title: 'Kunde Löschen',
    contentText: 'Hier können Sie diesen Kunden löschen.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, 
  },
  {
    tipId: 'generateInvoiceTip',
    title: 'Auftrag in Rechnung umwandeln',
    contentText: 'Aufträge können in Rechnungen umgewandelt werden.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, 
  },
  {
    tipId: 'settingsTip',
    title: 'Einstellungen',
    contentText: 'Hier können Sie die Einstellungen Ihres Unternehmens vornehmen.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, 
  },
  {
    tipId: 'noCustomersTip',
    title: 'Keine Kunden verfügbar!',
    contentText: 'Bitte legen Sie zunächst einen Kunden an.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer,
  },
  {
    tipId: 'editCustomerTip',
    title: 'Kunden Bearbeiten!',
    contentText: 'Hier können Sie die Kundenakte<br> Ansehen & Bearbeiten.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer,
  },
  {
    tipId: 'editItemTip',
    title: 'Artikel Bearbeiten!',
    contentText: 'Hier können Sie den jeweiligen Artikel, aufrufen und bearbeiten.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer,
  },
  {
    tipId: 'itemSettingsTip',
    title: 'Variante/Anzahl nachträglich Bearbeiten',
    contentText: 'Sie können diese Auswahl jederzeit erneut durch einen Mausklick auf "Anzahl" erreichen.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, // Hier fügst du die Checkbox-Footer hinzu
  },
  {
    tipId: 'itemViewTip',
    title: 'Artikel Bearbeiten',
    contentText: 'Hier können Sie die Artkel Bearbeiten',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, // Hier fügst du die Checkbox-Footer hinzu
  },
  {
    tipId: 'hilfsMittelSettingsTip',
    title: 'Pflegehilfsmittel nachträglich Bearbeiten',
    contentText: 'Sie können diese Auswahl jederzeit erneut durch einen Mausklick hier erreichen.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, // Hier fügst du die Checkbox-Footer hinzu
  },
  {
    tipId: 'NoItemsTip',
    title: 'Aktuell sind keine Artikel verfügbar.',
    contentText: 'Bevor ein Kunde angelegt werden kann, müssen Artikel vorhanden sein.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, // Hier fügst du die Checkbox-Footer hinzu
  },
  {
    tipId: 'KlickWeiterOrAbortTip',
    title: 'Abschließend',
    contentText: 'Nach Abschluss den Button [Weiter] betätigen oder [Abbrechen] für Abbruch.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, // Hier fügst du die Checkbox-Footer hinzu
  },
  {
    tipId: 'KlickWeiterTip',
    title: 'Abschließend',
    contentText: 'Nach Abschluss den Button > Weiter < betätigen.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, // Hier fügst du die Checkbox-Footer hinzu
  },
  {
    tipId: 'NoBoxesTip',
    title: 'Auswahl Boxen',
    contentText: 'Es sind keine Boxen zusammengestellt worden. Menü Katalog->Boxen zum hinzufügen von Boxen ',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, // Hier fügst du die Checkbox-Footer hinzu
  },
];

export default tips;