import axios from "axios";
import router from "@/router";
import { userStore } from "@/store/userStore";

// definition of request <pre> settings and headers
// these will be applied for all requests made with defAxios
const iAxios = axios.create({
  withCredentials: true,
  xsrfHeaderName: "X-CSRFToken",
  xsrfCookieName: "csrf_token",
  //xsrfHeaderName: "X-Csrftoken",
  //xsrfCookieName: "csrf_access_token",
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: process.env.VUE_APP_BASE_API_URL,
  responseType: "json"
});

  iAxios.interceptors.response.use(
    response => {
      const store = userStore();

      if (store.debug) console.log("🚀 ~ file: axiosInterface.js:25 ~ response.data:", response.data)

      return response;
    },
    async error => {
      const originalRequest = error.config;
  
      if (error.response && error.response.status === 401) {
        console.error("Response error:", error.response);
        try {
          router.push({ name: 'login' });
          return Promise.reject(error);
        } catch (err_1) {
          return Promise.reject(err_1);
        }
      } else if (error.response && error.response.status === 403) {
        if (router.currentRoute.value.name === 'MailConfirm') return;
        if (router.currentRoute.value.name === 'reset-password') return;
        if (router.currentRoute.value.name !== 'login') {
          //router.push({ name: 'login' });
          try {
            const res_1 = await new Promise((resolve, reject) => {
              iAxios.get(process.env.VUE_APP_BASE_API_URL + "/refresh")
                .then(res => {
                  resolve(res);
                })
                .catch(err => {
                  reject(err);
                });
            });
            return iAxios(originalRequest);
          } catch (err_1) {
            return Promise.reject(err_1);
          }
        }
        return Promise.reject(error);
      } else if (error.response && error.response.status === 419) {
        router.push({ name: 'login' });
        throw new Error('419: Zugang abgelaufen, erneuter Login notwendig');
      } else if (error.response && error.response.status === 500) {
        console.log("500: Serverfehler");
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    }
  );


export default iAxios;