<template>
    <div class="card p-1">
        <Inplace class="p-2 surface-card shadow-1 text-blue-300 border-round ">
            <template #display><i class="pi pi-info-circle"></i> Hilfe</template>
            <template #content>
        <p>
            Schritt 1 - <i class="pi pi-images text-xl text-blue-400"></i> Bild/er auswählen 
        </p>
        <p>
            Schritt 2 - <i class="pi pi-cloud-upload text-xl text-primary"></i> Bilder hochladen
        </p>
        </template>
        </Inplace>
          <FileUpload name="demo[]" url="https://storage.famtura.de/api/auth/files/upload"  customUpload @uploader="customBase64Uploader" :multiple="true" accept="image/*" :maxFileSize="1000000" @select="onSelectedFiles"
          :invalidFileSizeMessage="'Dateigröße muss kleiner als {1}. sein.'"

          >
            <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
               <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                    <div class="flex gap-2">
                        <Button v-tooltip="'Bilder auswählen'" @click="chooseCallback()" icon="pi pi-images" class="text-blue-400" rounded outlined></Button>
                        <Button v-tooltip="'Bilder Hochladen'" @click="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload" rounded outlined severity="success" :disabled="!files || files.length === 0"></Button>
                        <Button v-tooltip="'Abbrechen'" @click="clearCallback()" icon="pi pi-times" rounded outlined severity="danger" :disabled="!files || files.length === 0"></Button>
                    </div>
                    <ProgressBar :value="totalSizePercent" :showValue="false" :class="['md:w-20rem h-1rem w-full md:ml-auto', { 'exceeded-progress-bar': totalSizePercent > 100 }]"
                        ><span class="white-space-nowrap">{{ totalSize }}B / 1Mb</span></ProgressBar
                    >
                </div>
            </template>
            <template #content="{ files, uploadedFiles, removeUploadedFileCallback, removeFileCallback }">
                <div v-if="files.length > 0">
                    <h5>Pending</h5>
                    <div class="flex flex-wrap p-0 sm:p-5 gap-5">
                        <div v-for="(file, index) of files" :key="file.name + file.type + file.size" class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
                            <div>
                                <img role="presentation" :alt="file.name" :src="file.objectURL" width="100" height="50" class="shadow-2" />
                            </div>
                            <span class="font-semibold">{{ file.name }}</span>
                            <div>{{ formatSize(file.size) }}</div>
                            <Badge value="Pending" severity="warning" />
                            <Button icon="pi pi-times" @click="onRemoveTemplatingFile(file, removeFileCallback, index)" outlined rounded  severity="danger" />
                        </div>
                    </div>
                </div>

                <div v-if="uploadedFiles.length > 0">
                    <h5>Completed</h5>
                    <div class="flex flex-wrap p-0 sm:p-5 gap-5">
                        <div v-for="(file, index) of uploadedFiles" :key="file.name + file.type + file.size" class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
                            <div>
                                <img role="presentation" :alt="file.name" :src="file.objectURL" width="100" height="50" class="shadow-2" />
                            </div>
                            <span class="font-semibold">{{ file.name }}</span>
                            <div>{{ formatSize(file.size) }}</div>
                            <Badge value="Completed" class="mt-3" severity="success" />
                            <Button icon="pi pi-times" @click="removeUploadedFileCallback(index)" outlined rounded  severity="danger" />
                        </div>
                    </div>
                </div>
            </template>
            <template #empty>
                <div class="flex align-items-center justify-content-center flex-column">
                    <i class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400" />
                    <p class="mt-4 mb-0">Dateien für das Hochladen hierher ziehen oder auswählen mit <i class="pi pi-images text-blue-400"></i></p>
                </div>
            </template>
        </FileUpload>
    </div>
</template>
<script setup>
import {ref,inject} from "vue";
import { useToast } from "primevue/usetoast";
import fAxios from "@/store/faxiosInterface";
import { userStore } from "@/store/userStore";
import { storeToRefs } from 'pinia';

const store = userStore();
const toast = useToast();
const dialogRef = inject("dialogRef");

const totalSize = ref(0);
const totalSizePercent = ref(0);
const files = ref([]);

const onRemoveTemplatingFile = (file, removeFileCallback, index) => {
    removeFileCallback(index);
    totalSize.value -= parseInt(formatSize(file.size));
    totalSizePercent.value = totalSize.value / 10;
};

const onClearTemplatingUpload = (clear) => {
    clear();
    totalSize.value = 0;
    totalSizePercent.value = 0;
};

const onSelectedFiles = (event) => {
    files.value = event.files;
    files.value.forEach((file) => {
        totalSize.value += parseInt(formatSize(file.size));
    });
};

const uploadEvent = (callback) => {
    totalSizePercent.value = totalSize.value / 10;
    callback();
};

const onTemplatedUpload = () => {
    toast.add({ severity: "info", summary: "Success", detail: "File Uploaded", life: 3000 });
};

const formatSize = (bytes) => {
    const k = 1024;
    const dm = 3;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    if (bytes === 0) {
        return `0 ${sizes[0]}`;
    }

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

    return `${formattedSize} ${sizes[i]}`;
};

const customBase64Uploader = async (event) => {
    const formDataArray = [];
    
    for (const file of event.files) {
        const formData = new FormData();
        formData.append('filedata', file);

        try {
            let url='https://storage.famtura.de/api/auth/files/upload/'+store.apiKeyStorage+'/'+store.cid;

            console.log("🚀 ~ file: UploadFile.vue:137 ~ url:", url)

            const response = await fAxios.post(url, formData);
            formDataArray.push(response.data);
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    }
    console.log("Responses array:", formDataArray);
    closeDialog(formDataArray);
};

const closeDialog = (data) => {
    dialogRef.value.close(data);
};
</script>
