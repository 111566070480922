<script setup>
import { reactive, ref } from 'vue';
import axios from 'axios';

// Beispieladressen (du kannst sie durch deine Adressen ersetzen)
const addresses = reactive([
  'Admiralstr. 10, 10999 Berlin, Deutschland',
  'Ramlerstr. 24, 13355 Berlin, Deutschland',
  'Karl-Liebknecht-Str. 5, 10178 Berlin, Deutschland',
  'Karl-Marx-Allee 33, 10178 Berlin, Deutschland',
  'Berliner Str. 1, 13437 Berlin, Deutschland',
  'Münzstr. 18, 10178 Berlin, Deutschland',
  'Schönhauser Allee 36, 10435 Berlin, Deutschland',
  'Potsdamer Str. 5, 10785 Berlin, Deutschland',
  'Chausseestr. 4, 10115 Berlin, Deutschland',
  'Alexanderplatz 7, 10178 Berlin, Deutschland',
  'Torstr. 82, 10119 Berlin, Deutschland',
  'Kopenhagener Str. 30, 13407 Berlin, Deutschland',
  'Friedrichstr. 62, 10117 Berlin, Deutschland',
  'Lichtenberger Str. 4, 10317 Berlin, Deutschland',
  'Reinickendorfer Str. 48, 13347 Berlin, Deutschland',
]);

// OpenRouteService API-Schlüssel
const openRouteServiceApiKey = '5b3ce3597851110001cf6248b3a6a40916f14651b4c2a1553d258c6e';

// API Antwort für die Anzeige speichern
const responseMessage = ref(null);

// Linkausgabe
const googleMapsUrl = ref('');

// Fortschrittsanzeige
const progress = ref(0);

// Geocoding (Adressen in Koordinaten umwandeln) mit OpenRouteService
async function getCoordinates(address) {
  try {
    const response = await axios.get('https://api.openrouteservice.org/geocode/search', {
      params: {
        api_key: openRouteServiceApiKey,
        text: address,
        size: 1,
      },
    });

    if (response.data.features.length > 0) {
      const { coordinates } = response.data.features[0].geometry;
      return coordinates;
    } else {
      console.warn(`Keine Koordinaten für Adresse gefunden: ${address}`);
      return null;
    }
  } catch (error) {
    console.error(`Fehler beim Abrufen der Koordinaten für Adresse: ${address}`, error);
    return null;
  }
}

async function optimizeAndStartNavigation() {
    progress.value = 10;
  const coordinatesList = [];
  for (let i = 0; i < addresses.length; i++) {
    const coordinates = await getCoordinates(addresses[i]);
    if (coordinates) {
      coordinatesList.push(coordinates);
    }
    progress.value = ((i + 1) / addresses.length) * 100;

  }

  if (coordinatesList.length < addresses.length) {
    console.warn(`Einige Adressen konnten nicht geocodiert werden. Erwartet: ${addresses.length}, Erhalten: ${coordinatesList.length}`);
  }

  // Hier kannst du die Koordinaten weiterverarbeiten, z.B. eine optimierte Route berechnen und die Google Maps URL erstellen
  // Beispiel:
  googleMapsUrl.value = `https://www.google.com/maps/dir/${coordinatesList.map(coord => coord.reverse().join(',')).join('/')}`;
  responseMessage.value = `Erfolgreich geocodierte Adressen: ${coordinatesList.length}`;
}

</script>

<template>
  <button @click="optimizeAndStartNavigation">Optimierte Route berechnen</button>
  <ProgressBar :value="progress" > {{ progress.toFixed(0) }}%</ProgressBar>

  <a :href="googleMapsUrl" target="_blank">Google Maps Navigation starten</a>
  <div v-if="responseMessage">
    <h2>API Antwort:</h2>
    <pre>{{ responseMessage }}</pre>
  </div>
</template>