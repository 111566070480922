<template>
  <template v-if="!store.rules.activatedModules.shipping">
  <UpgradeLicence />
    </template>
  <template v-else>
  <div class="card">
    <Loading v-show="loading" />

    <DataTable v-model:expanded-rows="expandedRows" v-model:selection="selectedShippings" v-model:filters="filters"
      :value="shippings" editMode="row" scrollable ref="dataTableRef" scrollHeight="85vh" filterDisplay="row"
      tableStyle="min-width: 10%" resizableColumns columnResizeMode="fit" stripedRows selectionMode="row"
      dataKey="deliveryDocumentId" sortField="deliveryDocumentId" :sortOrder="-1" class="p-datatable-sm"
      @row-dblclick="handleRowDoubleClick" :virtualScrollerOptions="{
        itemSize: 46,
        delay: 300,
        showLoader: true,
        /* loading: lazyLoading, */
        numToleratedItems: 100
      }">
      <!--       <Column headerStyle="width: 2rem; text-align: center" footer="" bodyStyle="text-align: center; overflow: visible">
        <template #header>
          <Button type="button" outlined text size="small" icon="pi pi-user-plus" rounded @click="openDialog" />
        </template>
        <template #body="{ data }">
          <Button type="button" id="editCustomer" outlined text size="small" icon="pi pi-user-edit" aria-haspopup="true" aria-controls="overlay_menu" rounded @click="popUptoggle($event, data)" />
          <Menu ref="menu" id="overlay_menu" :model="popUpitems" :popup="true" />
        </template>
      </Column>
 -->

      <template #header>
        <EtikettenGenerator :etikettenDaten="selectedShippings" :packlistenButtonDisabled="packlistenButtonDisabled" />
      </template>


      <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>

      <Column field="deliveryDocumentId" header="Versand-id" footer="Versand-id" key="deliveryDocumentId" :showFilterMenu="false"
        :filterMenuStyle="{ width: '15%' }" style="min-width: 15%" sortable>
        <template #body="{ data }">
          <Badge :value="data.deliveryDocumentId" size="large" severity="help"></Badge>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText v-model="filterModel.value" v-tooltip.top.focus="'Zum Anwenden Enter drücken'" type="text"
            @keydown.enter="filterCallback()" @input="filterModel.value || filterCallback()" class="p-column-filter" />
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" autofocus />
        </template>
      </Column> 

      <Column field="customerId" header="Kunden -Id" footer="Kunden -Id" key="customerId"
        :showFilterMenu="false" :filterMenuStyle="{ width: '15%' }" style="min-width: 15%" sortable>
        <template #body="{ data }">
          <Badge :value="data.customerId" size="small" severity="success"></Badge>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText v-model="filterModel.value" v-tooltip.top.focus="'Zum Anwenden Enter drücken'" type="text"
            @keydown.enter="filterCallback()" @input="filterModel.value || filterCallback()" class="p-column-filter" />
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" autofocus />
        </template>
      </Column>
      <Column expander style="width: 5rem" />

      <Column v-if="!isMobile" class="" v-for="col of columns" :key="col.field" :field="col.field" :header="col.header"
        :footer="col.header" :showFilterMenu="false" style="width: 25%" sortable
        :sortField="isSortable(col.field) ? col.field + '_us' : col.field">
        <template #filter="{ filterModel, filterCallback }">
          <InputText v-model="filterModel.value" v-tooltip.top.focus="'Zum Anwenden Enter drücken'" type="text"
            @keydown.enter="filterCallback()" @input="filterModel.value || filterCallback()" class="p-column-filter" />
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" autofocus />
        </template>
        <template #loading>
          <Skeleton width="60%" height="2rem" />
        </template>
      </Column> 

       <Column field="shippingStatus" header="Versandstatus" footer="Versandstatus" hideFilterMenu :showFilterMenu="false"
        :filterMenuStyle="{ width: '8%' }" style="min-width: 8%">
        <template #body="{ data }">
          <div class="p-inputgroup">
            <Button icon="pi pi-angle-down" :label=data.shippingStatus :severity="getSeverity(data.shippingStatus)" text
              size=small rounded aria-haspopup="true" @click="popUptoggle($event, data)" aria-controls="overlay_menu"
              iconPos="right" />
          </div>
          <Menu ref="menu" id="overlay_menu" :model="popUpitems" :popup="true" />
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="statuses" placeholder="Auswählen"
            class="p-column-filter" style="min-width: 10%" :showClear="true">
          </Dropdown>
        </template>
      </Column>
       <Column field="shippingTracking" header="Sendungsverfolgung" footer="Sendungsverfolgung" hideFilterMenu
        :showFilterMenu="false" :filterMenuStyle="{ width: '8%' }" style="min-width: 8%">
        <template #body="{ data }">
          <div class="p-inputgroup">
            {{ data.shippingTracking }}
            <Button icon="pi pi-refresh" @click="refreshShippingTracking($event, data)" aria-controls="overlay_menu"
              iconPos="right" />
          </div>
        </template>
      </Column>
      <template #expansion="{ data }">
        <div class="p-3">
          <ul>
            <li v-for="product in data.itemList" :key="product.item_id_company">
              {{ product.quantity }} Stk. x
              {{ product.itemName }}
            </li>
          </ul>
        </div>
      </template>

    </DataTable>

  </div>
</template>
</template>

<script setup>
import { ref, reactive, onMounted, onBeforeMount, computed, markRaw } from "vue";
import KundenEditDialog from "./KundenEditDialog.vue";
import { useRouter } from "vue-router";
import Tip from "@/components/Tips/Tip.vue";
import { FilterMatchMode } from "primevue/api";
import { ShippingService } from "@/service/ShippingService";
import Loading from '@/components/Loading.vue';
import EtikettenGenerator from "@/components/EtikettenGenerator.vue";
import { useI18n } from "vue-i18n";
import { useDialog } from "primevue/usedialog";
import { defineAsyncComponent } from "vue";
import iAxios from "@/store/axiosInterface";
import { userStore } from "@/store/userStore";
import { storeToRefs } from 'pinia';
const ShippingTrackingConfig = defineAsyncComponent(() => import('@/views/ShippingTrackingConfig.vue'));
const ShippingTrackingFooter = defineAsyncComponent(() => import('@/views/ShippingTrackingFooter.vue'));

const store = userStore();
const dialog = useDialog();
const router = useRouter();
const initialize = ref(false); //wegen neuer Kunde usw.
const isMobile = ref(false);
const dialogVisible = ref(false);
const invoiceEditVisible = ref(false);
const lazyLoading = ref(false);
const loading = ref(true);
const dataTableRef = ref();
const loadLazyTimeout = ref();
const packlistenButtonDisabled = ref(true)
const shippings = ref([]);
const virtualShippings = ref([]);
/* const expandedRows = ref([]);
 */let rowData = null;
const selectedShippings = ref();
const expandedRows = ref([]);
const filteredData = ref([]);

const popUpLabel = reactive({ label: "", rowData: undefined });
const { t } = useI18n();

const refreshShippingTracking = (event, data) => {
  const options = {
    method: 'GET',
    url: 'https://api-test.dhl.com/track/shipments',
    params: { trackingNumber: '00340434292135100186' },
    headers: { 'DHL-API-Key': 'demo-key' },
  };

  iAxios.request(options).then(function (response) {
    console.log(response.data);
  }).catch(function (error) {
    console.error(error);
  });
}

const popUptoggle = (event, data) => {
  rowData = data;
  popUpLabel.label = rowData.name1;
  popUpLabel.rowData = rowData;
  menu.value.toggle(event);
};
const selectedUser = ref();
const menu = ref();
const popUpitems = computed(() => [
  {
    label: popUpLabel.label,
    items: [
      {
        label: t('OPEN'),
        icon: 'pi pi-hourglass',
        command: () => {
          if (rowData?.shippingStatus != 'OPEN') {
            ShippingService.setShippingStatus(rowData.shippingDocumentId, 'OPEN');
            rowData.shippingStatus = t('OPEN');
          }
        },
      },
      {
        label: t('SHIPPING_SENT'),
        icon: 'pi pi-truck',
        command: () => {
          if (rowData && rowData.shippingStatus != 'SHIPPING_SENT') {
            ShippingService.setShippingStatus(rowData.shippingDocumentId, 'SHIPPING_SENT');
            rowData.shippingStatus = t('SHIPPING_SENT');
          }
        },
      },
      {
        label: t('SHIPPING_RETURNED'),
        icon: 'pi pi-replay',
        command: () => {
          if (rowData && rowData.shippingStatus != 'SHIPPING_RETURNED') {
            ShippingService.setShippingStatus(rowData.shippingDocumentId, 'SHIPPING_RETURNED');
            rowData.shippingStatus = t('SHIPPING_RETURNED');
          }
        },
      },
      {
        separator: true
      },
      {
        label: 'Sendungsnummer',
        icon: 'pi pi-truck',
        command: () => {
          openShippingTrackingDialog(popUpLabel.rowData);
        }
      }
    ],
  },
]);

const openShippingTrackingDialog = (shippingEntry) => {

  const dialogRef = dialog.open(ShippingTrackingConfig, {
    props: {
      header: 'Sendungsverfolgung Konfigurieren',
      modal: true,
      closable: false,
      style: {
        width: '60vw',
      }
    },
    templates: {
      footer: markRaw(ShippingTrackingFooter),

    },
    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        if (buttonType == "Save") {
          
          iAxios.patch(process.env.VUE_APP_BASE_API_URL + "/company/parcel-tracking/" + shippingEntry.shippingDocumentId, { parcelService: dialogRef.data.shippingEntry.parcelService, trackingNumber: dialogRef.data.shippingEntry.trackingNumber });
        }
      }
    }
  });

  dialogRef.data = {
    shippingEntry: shippingEntry
  }
}

const columns = ref([
 //{ field: "customerId", header: "Kunden ID" },
   { field: "name1", header: "Empfänger" },
  { field: "postCode", header: "PLZ" },
  { field: "eventTime", header: "Vorgangszeit" },
  /*   
    { field: "registered", header: "Registriert" }, */
]);


const filters = ref({
  deliveryDocumentId  : { value: null, matchMode: FilterMatchMode.CONTAINS },
  customerId: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name1: { value: null, matchMode: FilterMatchMode.CONTAINS },
  postCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
  eventTime: { value: null, matchMode: FilterMatchMode.CONTAINS },
  //shippingStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
  shippingStatus: { value: t("OPEN"), matchMode: FilterMatchMode.CONTAINS },
  shippingTracking: { value: null, matchMode: FilterMatchMode.CONTAINS }
});

function isSortable(field) {
  return field === 'birthDate' || field === 'registered';
}

const dataForDialog = ref();

const loadCustomersLazy = (event) => {
  !lazyLoading.value && (lazyLoading.value = true);

  if (loadLazyTimeout.value) {
    clearTimeout(loadLazyTimeout.value);
  }
  //simulate remote connection with a timeout
  loadLazyTimeout.value = setTimeout(() => {
    let virtuelleKunden = [...shippings.value];
    let { first, last } = event;

    //load data of required page
    const loadedCustomers = shippings.value.slice(first, last);

    //populate page of virtual cars
    Array.prototype.splice.apply(virtuelleKunden, [...[first, last - first], ...loadedCustomers]);

    virtualShippings.value = virtuelleKunden;
    lazyLoading.value = false;
  }, Math.random() * 1000 + 800);
};

const handleRowDoubleClick = (event) => {
  const data = event.data;
  openDialog(data);
};

const openDialog = (data) => {
  if (data) {
    dataForDialog.value = data;
    dialogVisible.value = true;
    initialize.value = true;
  } else { //neuer Kunde
    dataForDialog.value = null;
    initialize.value = false;
    dialogVisible.value = true;
  }
};

const handleCustomerDelete = () => {
  console.log("handleCustomerDelete");
}

const handleDialogClose = () => {
  if (dataTableRef.value) {
    dataTableRef.value.filter();
  }
};

const formatDate = (value) => {
  const date = new Date(value);
  return date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

const statuses = ref([
  t("OPEN"),
  t("SHIPPING_SENT"),
  t("SHIPPING_RETURNED"),
]);

const getSeverity = (shippingStatus) => {
  switch (shippingStatus) {
    case t("OPEN"):
      return "info";

    case t("SHIPPING_RETURNED"):
      return "warning";

    case t("SHIPPING_SENT"):
      return "success";
  };
}

/* async function zeitMessen(funktion) {
  const start = performance.now();
  await funktion();
  const end = performance.now();
  const dauer = end - start;
  console.log(`Komplett geladen in ${dauer / 1000} Sekunden.`);
} */


//const etikettenDaten = ref([]);

/* const etikettenDaten = EtikettenGeneratorProps.etikettenDaten; */

//const etikettenGeneratorRef = ref(null);

async function werteLaden() {
  try {
    loading.value = true;
    await ShippingService.getShippingList().then((data) => {
      shippings.value = data.map((shippingList) => ({
        ...shippingList,
        name1: shippingList.customerDataSnap.isPrivateCustomer?shippingList.customerDataSnap.surName +' '+shippingList.customerDataSnap.firstName:shippingList.customerDataSnap.firmenName,
        postCode: shippingList.customerDataSnap.postCode,
        customerId: shippingList.customerDataSnap.customerId,
        shippingStatus: t(shippingList.shippingStatus),
      }));  //optimierbar würde um 1-1,5 sekunden schneller laden wenn vom backend kommt
      /*       if (shippings.value.length==0){
              router.push('/nocustomers');
            }    */   //numberOfCustomers=customers.length;
    });
    loading.value = false;
  } catch (Exception) {

    console.error("🚀 ~ file: ShippingPrepareView.vue:406 ~ Exception:", Exception)

    loading.value = false;
    /*     if (!shippings.value.length > 0) {
          router.push('/nocustomers'); 
        }*/
  }
  console.log("🚀 ~ file: ShippingPrepareView.vue:247 ~ shippings.value=data.map ~ shippings:", shippings)
}


const checkMobile = () => {
  isMobile.value = window.innerWidth <= 768; // Anpassen der Bildschirmbreite nach Bedarf
};

const selectAllRows = () => {
  setTimeout(() => {
    if (dataTableRef.value) {
      selectedShippings.value = [...dataTableRef.value.processedData];
      packlistenButtonDisabled.value = false;
    }
  }, 2000); // Ein kleiner Timeout, um sicherzustellen, dass die DataTable initialisiert ist
};


onMounted(() => {
  checkMobile();
  window.addEventListener('resize', checkMobile);
  selectAllRows();
  //etikettenGeneratorRef.value = ref(EtikettenGenerator);

}),

  onBeforeMount(async () => {
    //  await zeitMessen(werteLaden);
    await werteLaden();
  });

const closeDialog = () => {
  dialogVisible.value = false;
};
</script>

<style scoped>
:deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}

:deep(.titelline) {
  border-color: #8085a1;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
  border-style: solid;
}

:deep(.titel) {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5rem;
  padding: 40px 0px 0px 50px;
  text-align: left;
  line-height: 0;
}

:deep(.p-card .p-card-title) {
  font-size: 1.2rem;
  font-weight: 400;
}

@media (max-width: 768px) {
  :deep(.p-fluid .p-inputtext) {
    width: 100%;
    min-width: 52px;
  }

  :deep(.p-inputtext) {
    padding: 0.75rem 0.25rem 0.75rem 0.25rem;
  }
}

:deep(.p-datatable .p-datatable-tbody) {
  padding: 0;
}

:deep(.p-inputswitch) {
  width: 3rem;
  height: 1.45rem;
  margin-top: 2px;
  top: -2px;
}

.no-outline:focus {
  outline: none !important;
  box-shadow: none !important;
}
</style>
