<template>
  <div v-if="store?.whoAmIData?.firstName">
    <template v-if="store?.whoAmIData?.unreadTickets">
      <Badge class="support-button-badge" :value="Object.keys(store.whoAmIData.unreadTickets).length" severity="success"></Badge>
    </template>
    <transition name="button-fade" class="support-button" >
      <div v-if="!showWidget">
          <img class="h-5rem" @click="toggleWidget" :src="require('@/assets/support.svg')" alt="Support Icon">
      </div>
    </transition>
    <transition name="widget-fade">
      <div v-if="showWidget" class="widget-container h-50rem">
        <div class="p-2 widgetBalken flex justify-content-between align-items-center">
          <img :src="require('@/assets/support.svg')" alt="Logo" class="h-2rem">
          <span>
          <Button text @click="openAllTicket" class="text-2xl p-0 m-1 text-white" icon="pi pi-window-maximize" />
          <Button text @click="toggleWidget" class="text-2xl p-0 m-1 text-white" icon="pi pi-times" />
        </span>
        </div>
        <div :class="['overflow-auto', 'h-full', openRequest ? 'h-30rem' : 'h-50rem']">
          <div v-for="(message, messIndex) in chatMessages" :key="messIndex"
            :class="[messIndex === chatMessages.length - 1 ? 'lastMessage': '', 'message', message.isUser ? 'user-message' : 'support-message']" class="mx-3 mt-3 mb-5">
            <span class="message-icon pr-2"><i :class="['pi', message.isUser ? 'pi-user' : 'pi-ticket']"></i></span>
            <span>{{ message.text }}</span>
            <div v-if="message.options" v-for="(option, optIndex) in message.options" :key="optIndex">
              <div v-if="option.badge">
                <Button v-if="option.precondition || (typeof option.precondition === 'function' && option.precondition())" class="flex my-1 w-full" :disabled="chatMessages.length - 1 != messIndex"
                @click="selectOption(option)" :label="option.text" :badge="option.badge" />
              </div>
              <div v-else>
                <Button v-if="option.precondition || (typeof option.precondition === 'function' && option.precondition())" class="flex my-1 w-full" :disabled="chatMessages.length - 1 != messIndex"
                @click="selectOption(option)" :label="option.text" />
              </div>
            </div>
          </div>
          <div v-if="chatMessages.length - 1" class="m-3">
            <Button @click="rewindToLastSelectoin()" id="rewindButton" class="vertical-align-bottom w-full"><span class="pi pi-angle-double-left mr-2" /> Zurück zur Letzten auswahl</Button>
          </div>
        </div>
        <div v-if="openRequest" class="mx-3 mb-3">
          <span class="text-base font-semibold">Support Anfrage:</span>
          <Textarea id="comment" v-model="message" rows="3" class="w-full" cols="30" />
          <small v-if="message.length < 20 && !supportDocumentId">Mindestens 20 Zeichen eingeben.</small>
          <small v-else>Anzahl zeichen: {{ message.length }} </small>
          <Button @click="sendSupportRequest()"
            :disabled="(message.length < 20 && !supportDocumentId) || message.length <= 1" class="w-full"
            label="Sende uns eine Nachricht ✉️" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { userStore } from '@/store/userStore';
import iAxios from '@/store/axiosInterface';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import { nextTick } from 'vue';

const store = userStore();
const showWidget = ref(false);
const message = ref("");
const chatMessages = ref([]);
const openRequest = ref(false);
const router = useRouter();
const toast = useToast();
const supportDocumentId = ref();
const amountOpenTickets = ref();
const hasNoTickets = ref(false);

onMounted(async () => {
  store.whoAmIData = await store.whoAmI();
});

const rewindToLastSelectoin = () => {
  supportDocumentId.value = undefined;

  let i = chatMessages.value.length - 2;
  for (; i >= 0; i--) {
    if (chatMessages.value[i].options && chatMessages.value[i].options.length) {
      break;
    }
  }

  chatMessages.value.splice(i + 1, (chatMessages.value.length - 1) - i);
  scrollToLastMessage();
  openRequest.value = false;
}

const goToHandbuch = () => {
  router.push('/handbook');
}

const openRequestBox = () => {
  openRequest.value = true;
}

const scrollToLastMessage = async () => {
  await nextTick();
  let divLastMessage = document.getElementsByClassName('message');

  if (divLastMessage.length) {
    let lastDiv = divLastMessage[divLastMessage.length-1];
    lastDiv.scrollIntoView(false);
  }

  let rewindButton = document.getElementById('rewindButton');
  if (rewindButton) {
    rewindButton.scrollIntoView(false);
  }
}

const getAmountOpenTickets = async () => {
  const url = process.env.VUE_APP_BASE_API_URL + "/support/ticket-open";
  let element = await iAxios.get(url);

  amountOpenTickets.value = element.data[0].tickets;
  hasNoTickets.value = element.data[0].tickets == 0;

  return amountOpenTickets;
}

const loadOpenTickets = () => {
  const url = process.env.VUE_APP_BASE_API_URL + "/support/ticket-open";
  iAxios.get(url).then((response) => {
    if (!response.data[0].tickets) {
      chatMessages.value.push({
        text: 'Leider gibt es keine offene Support Anfragen.',
        isUser: false,
        options: [
          { text: "Neue Anfrage Stellen", action: () => { openRequestBox() } },
        ]
      });
      scrollToLastMessage();
    } else {
      let existingSuppReq = {
        text: 'Es gibt folgende offene Support Anfragen:',
        isUser: false,
        options: []
      };
      amountOpenTickets.value = response.data[0].tickets;
      hasNoTickets.value = response.data[0].tickets == 0;

      response.data[1].forEach(ticket => {
        existingSuppReq.options.push({ text: '\"' + ticket.ticketSubject + '\" vom ' + formatDate(ticket.createdTime), action: () => { loadTicket(ticket) }, precondition: true });
      })
      chatMessages.value.push(existingSuppReq);
      existingSuppReq.options.push({ text: 'Ein neues Problem', action: () => { openRequestBox() } });


      scrollToLastMessage();
    }
  }).catch((e) => {
    toast.add({
        severity: "error",
        summary: "Tickets nicht geladen.",
        detail: "Die offenen Tickets konnten nicht geladen werden.",
        life: 3000,
      });
  })
}

const loadTicket = async (ticket) => {
  supportDocumentId.value = ticket.supportDocumentId;
  await iAxios.get(process.env.VUE_APP_BASE_API_URL + "/support/ticket-read/" +supportDocumentId.value);

  ticket.ticketContent.forEach(contentEntry => {
    chatMessages.value.push({
      text: contentEntry.contentText,
      date: contentEntry.createdTime,
      isUser: contentEntry.createdBy === 'support' ? false : true,
    });
  });
  scrollToLastMessage();
  openRequestBox();
}

const formatDate = (value) => {
  const date = new Date(value);

  return date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
  }) + ' - ' + date.toLocaleTimeString("de-DE", {
    hour: "2-digit",
    minute: "2-digit"
  });
};

const steps = [
  { // 0
    text: 'Um was für eine Anfrage geht es?',
    isUser: false,
    options: [
      { text: "Ich habe ein Problem.", nextStep: 5, precondition: amountOpenTickets },
      { text: "Ich habe ein Problem.", nextStep: 1, precondition: hasNoTickets },
      { text: "Ich habe einen Verbesserungsvorschlag", nextStep: 4, preAction: () => { openRequestBox() }, precondition: true  },
      { text: "Ich habe ein anderes Anliegen", nextStep: 4, preAction: () => { openRequestBox() }, precondition: true },
    ]
  },
  { // 1
    text: "Haben Sie unser Handbuch schon durchgelesen?",
    isUser: false,
    options: [
      { text: "Ja", nextStep: 4, preAction: () => { openRequestBox() }, precondition: true },
      { text: "Nein", nextStep: 2, precondition: true }
    ]
  },
  { // 2
    text: "Lesen Sie bitte unser Handbuch durch, falls Sie weiterhin keine Antwort gefunden haben, senden Sie uns eine Support Anfrage.",
    isUser: false,
    options: [
      { text: "Gehe zum Handbuch", action: () => { goToHandbuch() }, precondition: true },
      { text: "Support Anfrage stellen", action: () => { openRequestBox() }, precondition: true }
    ]
  },
  { // 3
    text: 'Um welches Anliegen geht es?',
    isUser: false,
    options: [

    ]
  },
  { // 4
    text: 'Bitte nutzen Sie das Feld um ihre Anfrage zu beschreiben.',
    isUser: false,
    options: [

    ]
  },
  { // 5
    text: 'Haben Sie uns bereits wegen diesen Anliegen eine Anfrage erstellt?',
    isUser: false,
    options: [
      { text: "Ja, ich habe bereits eine Anfrage eröffnet.", nextStep: 3, preAction: () => { loadOpenTickets() }, precondition: amountOpenTickets, badge: amountOpenTickets },
      { text: "Nein, ich habe noch keine Anfrage eröffnet.", nextStep: 1, action: () => { openRequestBox() }, precondition: true },
    ]
  }
];

const selectOption = (option) => {
  if (option.preAction) {
    option.preAction();
  }
  if (option.nextStep) {
    chatMessages.value.push(steps[option.nextStep]);
  } else if (option.action) {
    option.action();
  }
  scrollToLastMessage();
}

const sendSupportRequest = () => {
  if (!supportDocumentId.value) {
    const url = process.env.VUE_APP_BASE_API_URL + "/support/ticket";

    iAxios.post(url, { ticketSubject: message.value.substring(0, 20), ticketContent: [{ contentText: message.value }] }).then((response) => {

      supportDocumentId.value = response.data.supportDocumentId;
      
      let tc = response.data.ticketContent.find(tc => tc.contentText === message.value);

      chatMessages.value.push({
        text: tc.contentText,
        isUser: true,
        options: [ ]
      });
      scrollToLastMessage();

      message.value = "";
    }).catch((e) => {
      toast.add({
        severity: "error",
        summary: "Antwort nicht abgeschickt",
        detail: "Ihre support antwort konnte nicht versendet werden.",
        life: 3000,
      });
    });
  } else {
    const url = process.env.VUE_APP_BASE_API_URL + "/support/ticket/" + supportDocumentId.value;
    iAxios.post(url, { companyUid: store.whoAmIData.cid, ticketSubject: message.value.substring(0, 20), ticketContent: [{ contentText: message.value }] }).then((response) => {
      let tc = response.data.ticketContent.find(tc => tc.contentText === message.value);

      chatMessages.value.push({
        text: tc.contentText,
        isUser: true,
        options: [ ]
      });
      scrollToLastMessage();

      message.value = "";
    }).catch((e) => {
      toast.add({
        severity: "error",
        summary: "Antwort nicht abgeschickt",
        detail: "Ihre support antwort konnte nicht versendet werden.",
        life: 3000,
      });
    });
  }
}

function openAllTicket () {
  toggleWidget();
  router.push('/ticketsView');
}

function toggleWidget() {
  getAmountOpenTickets();

  steps[0].text = "Hi " + store.whoAmIData.firstName + " 👋 \n Wie können wir helfen?";

  if (chatMessages.value.length == 0) {
    chatMessages.value.push(steps[0]);
    scrollToLastMessage();
  }
  showWidget.value = !showWidget.value;
}
</script>

<style scoped>
.support-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  cursor: pointer;
  z-index: 1001;
  border-radius: 50%;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease;
}
.support-button-badge {
  position: fixed;
  bottom: 80px;
  right: 15px;
  z-index: 1002;
  transition: transform 0.3s ease;
}

/* Wenn der Cursor über element1 ist, werden beide Elemente vergrößert */
.support-button:hover, .support-button:hover ~ .support-button-badge {
  transform: scale(1.1);
}

/* Wenn der Cursor über element2 ist, werden beide Elemente vergrößert */
.support-button-badge:hover, .support-button-badge:hover ~ .support-button {
  transform: scale(1.1);
}

.widget-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
  z-index: 1000;
  border-radius: 10px;
  overflow: hidden;
  opacity: 1;
}

.widgetBalken {
  background-color: #101827;
}

.widget-fade-enter-active,
.widget-fade-leave-active {
  transition: opacity 0.5s ease;
}

.widget-fade-enter,
.widget-fade-leave-to {
  opacity: 0;
}

.button-fade-enter-active,
.button-fade-leave-active {
  transition: opacity 0.5s ease;
}

.button-fade-enter,
.button-fade-leave-to {
  opacity: 0;
}
</style>