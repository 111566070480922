import {ref} from 'vue';

/** Datumsfunktion für die umwandlung nach dd-mm-yyyy */
export const formatDate = (date,de) => {
    var year = date.getFullYear().toString();
    var month = (date.getMonth() + 101).toString().substring(1);
    var day = (date.getDate() + 100).toString().substring(1);
    return year + "-" + month + "-" + day;
  };
  

// Datumsfunktion für die Umwandlung nach dd.mm.yyyy
export const formatDatum = (value) => {
  if (value == null) return "";
  // Überprüfen Sie, ob das Eingabedatum gültig ist
  if (isNaN(Date.parse(value))) {
    console.error(`Ungültiges Datum: ${value}`);
    console.error(`Objekt Details: ${JSON.stringify(value, null, 2)}`);
    return value;
  }

  const date = new Date(value);
  const isoString = date.toISOString();

  // Extrahieren Sie das Datum aus dem ISO String
  const year = isoString.slice(0, 4);
  const month = isoString.slice(5, 7);
  const day = isoString.slice(8, 10);

  // Formatieren Sie das Datum im Format dd.mm.yyyy
  return `${day}.${month}.${year}`;
};

export const formatDatumZeit = (value) => {
  if (!value) return "";
  // Überprüfen Sie, ob das Eingabedatum gültig ist
  if (isNaN(Date.parse(value))) {
    console.error(`Ungültiges Datum: ${value}`);
    console.error(`Objekt Details: ${JSON.stringify(value, null, 2)}`);
    return value;
  }
  const date = new Date(value);
  const isoString = date.toISOString();

  // Extrahieren Sie das Datum aus dem ISO String
  const year = isoString.slice(0, 4);
  const month = isoString.slice(5, 7);
  const day = isoString.slice(8, 10);

  const hour = isoString.slice(11, 13);
  const minute = isoString.slice(14, 16);

  // Formatieren Sie das Datum im Format dd.mm.yyyy
  return `${day}.${month}.${year} ${hour}:${minute} Uhr`;
};

  export const formatCurrency = (value) => {
    if (!value) return "";
    return value.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
    });
  };
  export const formatCurrencyWithZero = (value) => {
    if (!value) value=0;
    return value.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
    });
  };