<template>
  <div>
    <div class="my-1 text-center offset-2 col-12">
      <!-- <img src="@/assets/register.jpg" class="img-fluid" /> -->
    </div>
    <div class="alert alert-danger col-md-8 offset-2" v-if="error">
      {{ errorDisplayText }}
    </div>
    <form @submit.prevent="submitData">
      <div class="form-row">
        <div class="form-group" :class="columnSizesForm">
          <InputText
            v-model="values.email"
            type="email"
            class="form-control"
            id="registerEmail"
            name="email"
            placeholder="E-Mail Adresse"
            :class="{ 'p-invalid': errors.email }"
          />
          <small v-if="errors.email" class="p-error">{{ errors.email }}</small>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group" :class="columnSizesForm">
          <InputText
            v-model="values.password"
            type="password"
            class="form-control"
            id="new-password"
            name="password"
            placeholder="Passwort"
            :class="{ 'p-invalid': errors.password }"
          />
          <small v-if="errors.password" class="p-error">{{ errors.password }}</small>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group" :class="columnSizesForm">
          <InputText
            v-model="values.confirmPassword"
            type="password"
            class="form-control"
            id="confirmPassword"
            name="confirmPassword"
            placeholder="Passwort wiederholen"
            :class="{ 'p-invalid': errors.confirmPassword }"
          />
          <small v-if="errors.confirmPassword" class="p-error">{{ errors.confirmPassword }}</small>
        </div>
      </div>
      <div class="form-row mt-3">
        <div class="form-group" :class="columnSizesForm">
          <div class="d-grid">
            <Button
              :loading="isLoading"
              label="Registrieren"
              type="submit"
              class="btn bg-vue text-vue"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, reactive } from "vue";
import * as yup from "yup";
import { userStore } from "@/store/userStore";
import { useToast } from "primevue/usetoast";

const store = userStore();
const toast = useToast();

const values = reactive({
  email: "",
  password: "",
  confirmPassword: "",
});

const error = ref("");
const isLoading = ref(false);

const columnSizesForm =
  "md:col-8 md:offset-2 sm:col-8 sm:offset-2 col-8 offset-2";

// Schema zur Validierung der Felder
const schema = yup.object().shape({
  email: yup
    .string()
    .required("E-Mail Adresse wird benötigt.")
    .email("Keine gültige E-Mail Adresse."),
  password: yup
    .string()
    .required("Passwort wird benötigt.")
    .min(6, "Passwort muss mindestens 6 Zeichen lang sein."),
  confirmPassword: yup
    .string()
    .required("Passwortbestätigung wird benötigt.")
    .oneOf([yup.ref("password")], "Passwörter stimmen nicht überein."),
});

// Validierungsfehler
const errors = reactive({});

async function validateFields() {
  try {
    await schema.validate(values, { abortEarly: false });
    Object.keys(errors).forEach((key) => {
      errors[key] = "";
    });
    return true;
  } catch (err) {
    err.inner.forEach((validationError) => {
      errors[validationError.path] = validationError.message;
    });
    return false;
  }
}

function errorDisplayText() {
  if (error.value) {
    return error.value;
  }
  return "";
}

async function submitData() {
  const isValid = await validateFields();
  // Überprüfen, ob es Validierungsfehler gibt
  if (!isValid) {
    return;
  }
  isLoading.value = true;
  error.value = "";
  store
    .signup({
      email: values.email,
      password: values.password,
    })
    .then(() => {
      isLoading.value = false;
      toast.add({
        severity: "success",
        summary: "Registrierung abgeschickt",
        detail:
          "Bitte die Registrierung per E-Mail abschließen. Tipp: Schauen Sie auch im Spam-Ordner nach, falls keine E-Mail da ist.",
        life: 3000,
      });
      store.registersStayOpen = false;
    })
    .catch((error) => {
      error.value = error.message;
      toast.add({
        severity: "error",
        summary: "Fehler",
        detail: error.message,
        life: 3000,
      });
      isLoading.value = false;
    });
}
</script>

<style scoped></style>
