<template>
    <div>
      <h2>Daten-Import</h2>
      <h3>Vorrausetzungen: -> beide EP's eingeben, mindestens 1 Datensatz in der EP</h3>
      GET-EP: <InputText v-model="getEP" placeholder="bsp. /products/item/" @blur="getColums"/>
      POST-EP: <InputText v-model="postEP" placeholder="sp. /products/item" />
      <CsvImport v-if="getEP && postEP && dbColumns.length>0" :getEP="getEP" :postEP="postEP" :dataSendKind="'row'" :preview=true :dbColumns="dbColumns"></CsvImport>
    </div>
    <Divider />
    <div>
      <h2>Kunden-Daten Korrektur</h2>
      <Button label="Daten Laden" @click="ladeKundenDaten" v-if="!datengeladen"/>
      <Button label="Daten Neu Laden" @click="datengeladen=false" v-if="datengeladen"/>
      <div class="flex align-items-center mt-2">
        <span>Privat-Kunden</span><InputSwitch class="ml-2" v-model="isPrivat" :binary="true"  v-if="!datengeladen"/>
      </div>
      <ProgressBar v-if="ladeFortschritt" :value="ladeFortschritt" />

      <Fieldset v-if="datengeladen && isPrivat"
        ref="probAnschriftRef"
        :toggleable="true"
        class="field col-12 bla"
        :collapsed="true"
        :pt="{
          legend: {
            class: 'p-0 m-0 bg-white border-none',
            style: { left: '-25px!important', position: 'relative' },
          },
          togglerIcon: { class: 'p-0 m-0 hidden' },
          toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
          toggler: { class: 'py-0 my-0' },
          root: { class: 'p-0 my-0' },
        }">
                <template #legend>
          <div class="flex align-items-center gap-0 px-0">
            <i
              class="text-xl mx-3"
              :class="
                probAnschriftRef?.d_collapsed
                  ? 'pi pi-chevron-down'
                  : 'pi pi-chevron-up'
              "></i>
            <span class="text-lg">Problematische Kunden-Daten mit Anschrift, Anzahl:{{ problemKundenAnschrift.length }}</span>
          </div>
        </template>
        <DataTable :value="problemKundenAnschrift" stripedRows size="small" editMode="cell" @cell-edit-complete="onCellEditComplete">
        <Column v-for="col in columns" :field="col.field" :header="col.header" :key="col.field">
        <template #body="slotProps">
          {{ slotProps.data[col.field] }}  
      </template>
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[col.field]" />
        </template>
        </Column>
        <Column header="Aktionen">
          <template #body="slotProps">
           <Button label="Speichern"  severity="success" @click="speichereKunde(slotProps.data)" />
         </template>
        </Column>
      </DataTable>
      </Fieldset>

      <Fieldset v-if="datengeladen && !isPrivat"
        ref="probAnschriftRef"
        :toggleable="true"
        class="field col-12 bla"
        :collapsed="true"
        :pt="{
          legend: {
            class: 'p-0 m-0 bg-white border-none',
            style: { left: '-25px!important', position: 'relative' },
          },
          togglerIcon: { class: 'p-0 m-0 hidden' },
          toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
          toggler: { class: 'py-0 my-0' },
          root: { class: 'p-0 my-0' },
        }">
                <template #legend>
          <div class="flex align-items-center gap-0 px-0">
            <i
              class="text-xl mx-3"
              :class="
                probAnschriftRef?.d_collapsed
                  ? 'pi pi-chevron-down'
                  : 'pi pi-chevron-up'
              "></i>
            <span class="text-lg">Problematische Kunden-Daten mit Anschrift, Anzahl:{{ problemKundenAnschrift.length }}</span>
          </div>
        </template>
        <DataTable :value="problemKundenAnschrift" stripedRows size="small" editMode="cell" @cell-edit-complete="onCellEditComplete">
        <Column v-for="col in columnsCompany" :field="col.field" :header="col.header" :key="col.field">
        <template #body="slotProps">
          {{ slotProps.data[col.field] }}  
      </template>
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[col.field]" />
        </template>
        </Column>
        <Column header="Aktionen">
          <template #body="slotProps">
           <Button label="Speichern"  severity="success" @click="speichereKunde(slotProps.data)" />
         </template>
        </Column>
      </DataTable>
      </Fieldset>


      <Fieldset v-if="datengeladen && isPrivat"
        ref="probNamenRef"
        :toggleable="true"
        class="field col-12 bla"
        :collapsed="true"
        :pt="{
          legend: {
            class: 'p-0 m-0 bg-white border-none',
            style: { left: '-25px!important', position: 'relative' },
          },
          togglerIcon: { class: 'p-0 m-0 hidden' },
          toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
          toggler: { class: 'py-0 my-0' },
          root: { class: 'p-0 my-0' },
        }">
                <template #legend>
          <div class="flex align-items-center gap-0 px-0">
            <i
              class="text-xl mx-3"
              :class="
                probNamenRef?.d_collapsed
                  ? 'pi pi-chevron-down'
                  : 'pi pi-chevron-up'
              "></i>
            <span class="text-lg">Problematische Kunden-Daten mit Name Anzahl:{{ problemKundenName.length }}</span>
          </div>
        </template>
        <DataTable :value="problemKundenName" stripedRows size="small" editMode="cell" @cell-edit-complete="onCellEditComplete">
        <Column v-for="col in columns" :field="col.field" :header="col.header" :key="col.field">
        <template #body="slotProps">
          {{ slotProps.data[col.field] }}  
                </template>
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[col.field]" />
        </template>
        </Column>
        <Column header="Aktionen">
          <template #body="slotProps">
            <Button label="Speichern"  severity="success" @click="speichereKunde(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
      </Fieldset>


      <Fieldset v-if="datengeladen && !isPrivat"
        ref="probFirmaRef"
        :toggleable="true"
        class="field col-12 bla"
        :collapsed="true"
        :pt="{
          legend: {
            class: 'p-0 m-0 bg-white border-none',
            style: { left: '-25px!important', position: 'relative' },
          },
          togglerIcon: { class: 'p-0 m-0 hidden' },
          toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
          toggler: { class: 'py-0 my-0' },
          root: { class: 'p-0 my-0' },
        }">
                <template #legend>
          <div class="flex align-items-center gap-0 px-0">
            <i
              class="text-xl mx-3"
              :class="
                probFirmaRef?.d_collapsed
                  ? 'pi pi-chevron-down'
                  : 'pi pi-chevron-up'
              "></i>
            <span class="text-lg">Problematische Kunden-Daten mit Firmennamen Anzahl:{{ problemKundenName.length }}</span>
          </div>
        </template>
        <DataTable :value="problemKundenName" stripedRows size="small" editMode="cell" @cell-edit-complete="onCellEditComplete">
        <Column v-for="col in columnsCompany" :field="col.field" :header="col.header" :key="col.field">
        <template #body="slotProps">
          {{ slotProps.data[col.field] }}  
                </template>
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[col.field]" />
        </template>
        </Column>
        <Column header="Aktionen">
          <template #body="slotProps">
            <Button label="Speichern"  severity="success" @click="speichereKunde(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
      </Fieldset>


      <Fieldset v-if="datengeladen && isPrivat" 
        ref="correctedCustomerRef"
        :toggleable="true"
        class="field col-12 bla"
        :collapsed="true"
        :pt="{
          legend: {
            class: 'p-0 m-0 bg-white border-none',
            style: { left: '-25px!important', position: 'relative' },
          },
          togglerIcon: { class: 'p-0 m-0 hidden' },
          toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
          toggler: { class: 'py-0 my-0' },
          root: { class: 'p-0 my-0' },
        }">
                <template #legend>
          <div class="flex align-items-center gap-0 px-0">
            <i
              class="text-xl mx-3"
              :class="
                correctedCustomerRef?.d_collapsed
                  ? 'pi pi-chevron-down'
                  : 'pi pi-chevron-up'
              "></i>
            <span class="text-lg">Korrigierbare Kunden-Daten Anzahl:{{ korrigierteKunden.length }}</span>
          </div>
        </template>
      <DataTable :value="korrigierteKunden" stripedRows size="small" editMode="cell" @cell-edit-complete="onCellEditComplete">
        <Column v-for="col in columns" :field="col.field" :header="col.header" :key="col.field">
        <template #body="slotProps">
          {{ slotProps.data[col.field] }}  
        </template>
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[col.field]" />
        </template>
        </Column>

        <Column header="Aktionen">
          <template #body="slotProps">
            <Button label="Nicht korrigieren" class="text-xs"  severity="danger" @click="removeFromCorrecting(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
      <Button label="Alle Speichern" :disabled="korrigierteKunden?.length < 1" severity="success" @click="speichereAlleKunden" style="margin-top: 10px;" />
      </Fieldset>
 

      <Fieldset v-if="datengeladen && !isPrivat" 
        ref="correctedCustomerRef"
        :toggleable="true"
        class="field col-12 bla"
        :collapsed="true"
        :pt="{
          legend: {
            class: 'p-0 m-0 bg-white border-none',
            style: { left: '-25px!important', position: 'relative' },
          },
          togglerIcon: { class: 'p-0 m-0 hidden' },
          toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
          toggler: { class: 'py-0 my-0' },
          root: { class: 'p-0 my-0' },
        }">
                <template #legend>
          <div class="flex align-items-center gap-0 px-0">
            <i
              class="text-xl mx-3"
              :class="
                correctedCustomerRef?.d_collapsed
                  ? 'pi pi-chevron-down'
                  : 'pi pi-chevron-up'
              "></i>
            <span class="text-lg">Korrigierbare Kunden-Daten Anzahl:{{ korrigierteKunden.length }}</span>
          </div>
        </template>
      <DataTable :value="korrigierteKunden" stripedRows size="small" editMode="cell" @cell-edit-complete="onCellEditComplete">
        <Column v-for="col in columnsCompany" :field="col.field" :header="col.header" :key="col.field">
        <template #body="slotProps">
          {{ slotProps.data[col.field] }}  
        </template>
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[col.field]" />
        </template>
        </Column>

        <Column header="Aktionen">
          <template #body="slotProps">
            <Button label="Nicht korrigieren" class="text-xs"  severity="danger" @click="removeFromCorrecting(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
      <Button label="Alle Speichern" :disabled="korrigierteKunden?.length < 1" severity="success" @click="speichereAlleKunden" style="margin-top: 10px;" />
      </Fieldset>

    </div>
  </template>
  
  <script setup>
  import { ref,watch } from 'vue';
  import { CustomerService } from "@/service/CustomerService.js";
  import iAxios from "@/store/axiosInterface";
  import { kundenDatenKorrigieren } from '@/utils/customerRepair';
  import CsvImport from "@/components/CsvImport.vue";

  const columns = [
    { field: 'firstName', header: 'Vorname' },
    { field: 'surName', header: 'Nachname' },
    { field: 'street', header: 'Straße' },
    { field: 'houseNumber', header: 'Hausnummer' },
    { field: 'city', header: 'Stadt' },
    { field: 'postCode', header: 'Postleitzahl' },
  ];
  const columnsCompany = [
    { field: 'firmenName', header: 'Firmenname' },
    { field: 'street', header: 'Straße' },
    { field: 'houseNumber', header: 'Hausnummer' },
    { field: 'city', header: 'Stadt' },
    { field: 'postCode', header: 'Postleitzahl' },
  ];
  const datengeladen = ref(false);
  const probAnschriftRef = ref(null);
  const probNamenRef = ref(null);
  const probFirmaRef = ref(null);
  const correctedCustomerRef = ref(null);
  const getEP = ref('');
  const postEP = ref('');
  const korrigierteKunden = ref([]);
  const problemKundenName = ref([]);
  const problemKundenAnschrift = ref([]);
  const problemKundenFirma = ref([]);
  const ladeFortschritt = ref(0);
  const isPrivat= ref(true);

  const onCellEditComplete = (event) => {
    let { data, newValue, field } = event;
            if (newValue.trim().length > 0) data[field] = newValue;
            else event.preventDefault();
};

  const ladeKundenDaten = async () => {
    datengeladen.value = false;
    try {
      ladeFortschritt.value = 25;
      let kundenDaten = await CustomerService.getCustomerXLarge();
      //kundendaten filtern nur nach isPrivateCustomer === false
      kundenDaten = kundenDaten.filter(k => k.isPrivateCustomer === isPrivat.value);
      ladeFortschritt.value = 50;
      
      const ergebnis = kundenDatenKorrigieren(kundenDaten,isPrivat.value);
      ladeFortschritt.value = 75;
     let kDaten= await ergebnis;
     korrigierteKunden.value =kDaten.korrigierteDaten;
     problemKundenName.value = kDaten.problematischeEintraegeName;
     problemKundenAnschrift.value = kDaten.problematischeEintraegeAnschrift;
     problemKundenFirma.value = kDaten.problematischeEintraegeFirma;
      ladeFortschritt.value = 100;
      
      setTimeout(() => {
        ladeFortschritt.value = 0;
      }, 500);
    } catch (error) {
      console.error('Fehler beim Laden der Kundendaten:', error);
      ladeFortschritt.value = 0;
    }
    datengeladen.value = true;
  };
  
  const removeFromCorrecting = (kunde) => {
    const index = korrigierteKunden.value.indexOf(kunde);
    if (index > -1) {
      korrigierteKunden.value.splice(index, 1);
    }
  };
  
  const speichereKunde = async (kunde) => {
    try {
      ladeFortschritt.value = 25;
     // const customerUid = kunde;
      await iAxios.patch('/company/customer-details', kunde);
      ladeFortschritt.value = 100;
      
      setTimeout(() => {
        ladeFortschritt.value = 0;
      }, 500);
    } catch (error) {
      console.error('Fehler beim Speichern der Kundendaten:', error);
      ladeFortschritt.value = 0;
    }
  };

  const speichereAlleKunden = async () => {
    try {
      ladeFortschritt.value = 25;
      await Promise.all(korrigierteKunden.value.map(async (kunde) => {
        await iAxios.patch('/company/customer-details', kunde);
      }));
      ladeFortschritt.value = 100;
      
      setTimeout(() => {
        ladeFortschritt.value = 0;
      }, 500);
    } catch (error) {
      console.error('Fehler beim Speichern aller Kundendaten:', error);
      ladeFortschritt.value = 0;
    }
  };

  const dbColumns = ref([]);

const fetchColumns = async (endpoint) => {
  try {
    const response = await iAxios.get(endpoint);
    const data = await response.data;

    console.log("🚀 ~ file: DbTools.vue:409 ~ Object.keys(data[0] || {}):", Object.keys(data[0] || {}))

    // Annahme: Die API gibt eine Liste von Objekten zurück
    const keys = Object.keys(data[0] || {});
    return keys.map(key => ({
      key: key,
      label: key, // Beispielhafte Label-Erstellung
      required: false, // Standardmäßig nicht erforderlich
      allowDefaultValue: true // Standardmäßig keine Standardwerte erlaubt
    }));
  } catch (error) {
    console.error('Fehler beim Abrufen der Spalten:', error);
    return [];
  }
};

// Überwachen von Änderungen an EP und Aktualisieren von dbColumns
const getColums= async()=>{ 
    dbColumns.value = await fetchColumns(getEP.value) || [];

    console.log("🚀 ~ file: DbTools.vue:423 ~ dbColumns.value:", dbColumns.value)

};
  </script>
  
  <style scoped>
  .p-datatable-striped tbody tr:nth-child(even) {
    background: #f9f9f9;
  }
  </style>
  