<template>
    <div>
<!--         <KundenEditDialog v-model:visible="newCustomer" :initialize="false" />
 -->        <Card>
            <template #content>
                <div>
                    <div v-if="isItemAvailible">
                        <Tip :target="'#noCustomers'" :tipId="'noCustomersTip'" :placement="'left'" :overlay="false" />
                        <DataTable>
                            <Column headerStyle="width: 2rem; text-align: center">
                                <template #header>
                                    <Button id="noCustomers" type="button" outlined text size="small" icon="pi pi-user-plus"
                                        rounded @click="openKundenEditDialog" />
                                </template>
                            </Column>
                            <Column field="customerId" header="Kunden -Id" key="customerId"
                                :showFilterMenu="false" style="min-width: 150px">
                            </Column>
                            <Column v-if="!isMobile" class="" v-for="col of columns" :key="col.field" :field="col.field"
                                :header="col.header" :showFilterMenu="false" style="width: 25%">
                            </Column>
                        </DataTable>
                    </div>
                </div>
                <div v-if="!isItemAvailible">
                    <Tip v-if="!isMobile" :tipId="'NoItemsTip'" :target="'#pv_id_1_2_header'" :placement="'right'" :overlay="false" :route="'/itemsEditView'"
                        :routeLabel="'Artikel anlegen'" :sideTitel="'Artikel'" :showCheckbox="false"/>
                    <Tip v-else :tipId="'NoItemsTip'"  :overlay="false" :route="'/itemsEditView'"
                        :routeLabel="'Artikel anlegen'" :sideTitel="'Artikel'" :showCheckbox="false"/>
                    <!--                     <h3 style="color:red">- Bevor ein Kunde angelegt werden kann, müssen Artikel vorhanden
                        sein. Aktuell sind jedoch keine Artikel verfügbar.</h3> -->
                    <!--                     <Button label="Neuen Artikel anlegen" @click="router.push('/itemsEditView')" />
 -->
                </div>
            </template>
        </Card>
    </div>
</template>
<script setup>
import { ref, onMounted,defineAsyncComponent,markRaw,reactive } from 'vue';
import { userStore } from "@/store/userStore";
import { storeToRefs } from 'pinia';
import { useRouter } from "vue-router";
import { useDialog } from "primevue/usedialog";
import iAxios from '@/store/axiosInterface';
const KundenEditDialog = defineAsyncComponent(() =>
  import("@/views/KundenEditDialog.vue")
);
const KundenEditDialogHeader = defineAsyncComponent(() =>
  import("@/views/KundenEditDialogHeader.vue")
);
const store = userStore();
const router = useRouter();

const isMobile = ref(false);
const dialog = useDialog();

const companyItems = ref();
const isItemAvailible = ref(false);

const columns = ref([
  /*   { field: "customerId", header: "Kunden ID" },
   */ { field: "firstName", header: "Vorname" },
    { field: "surName", header: "Nachname" },
    { field: "postCode", header: "PLZ" },
    { field: "registered", header: "Registriert" },
]);
async function getItems() {
    try {
        const response = await iAxios.get(process.env.VUE_APP_BASE_API_URL +"/products/item");
        companyItems.value = response.data;
       // const length = Object.keys(companyItems.value).length;
       const length = companyItems.value.length;
        isItemAvailible.value = (length > 0);
        return companyItems.value;
    } catch (e) {
        router.push('/');
    }
}

const newCustomer = reactive({
  isPrivateCustomer: false,
  customerId: "",

  created_de: "",
  created_us: "",
  updated_de: "",
  updated_us: "",
  address1: "",
  address2: "",
  firmenName: "",
  salutation: "",
  firstName: "",
  surName: "",
  name2: "",
  street: "",
  houseNumber: "",
  postCode: "",
  city: "",
  emailAddress: "",
  phone: "",
  mobilePhone: "",
});

const openKundenEditDialog = (event) => {
  const data = ref(event.data ? event.data : event);

  const originalData = data.value;
  const dialogRef = dialog.open(KundenEditDialog, {
    props: {
      header: "Kunden hinzufügen",
      modal: true,
      closable: false,
      style: {
        width: "65vw",
        padding:"100px",
      },
      breakpoints: {
        "960px": "75vw",
        "640px": "100vw",
      },
    },
    templates: {
      header: markRaw(KundenEditDialogHeader),

    },
    data: {
      newCustomer,
    },    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        if (buttonType == "Save") {
          router.push("/kundenView");
          //save(data.value, originalData);
        } else if (buttonType == "Cancel") {
          //Object.assign(data.value, originalData);
        }
      }
    },
  });
};

const checkMobile = () => {
    isMobile.value = window.innerWidth <= 768; // Anpassen der Bildschirmbreite nach Bedarf
};

onMounted(() => {
    checkMobile();
    getItems();
});


</script>