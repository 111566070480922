import axios from "axios";
import router from "@/router";
import { userStore } from "@/store/userStore";
import { storeToRefs } from 'pinia';

// definition of request <pre> settings and headers
// these will be applied for all requests made with defAxios
const fAxios = axios.create({
  withCredentials: true,
  xsrfHeaderName: "X-Csrftoken",
  xsrfCookieName: "csrf_access_token",
  baseURL: process.env.VUE_APP_BASE_API_URL,
  responseType: "json"
});

  fAxios.interceptors.response.use(
    config => {
      return config;
    },
    async error => {
      const originalRequest = error.config;
      const store = userStore();
      if (error.response) {
        switch (error.response.status) {
          case 401:
            if (router.currentRoute.value.name !== 'login' && error.response.status === 403) {
              store.setExpired(true);
            } 
            try {
              const res_1 = await new Promise((resolve, reject) => {         
                fAxios.get(process.env.VUE_APP_BASE_API_URL + "/refresh")
                  .then(res => {
                    resolve(res);
                  })
                  .catch(err => {
                    reject(err);
                  });
              });
              return await Promise.reject(error);
            } catch (err_1) {
              return await Promise.reject(err_1);
            }
          case 403:
            if (router.currentRoute.value.name === 'MailConfirm') return;
            if (router.currentRoute.value.name === 'reset-password') return;
            if (router.currentRoute.value.name !== 'login') {
              store.setExpired(true);
              router.push('/');
            }
            return Promise.reject(error);
          case 404:
           // console.log('nicht vorhanden');
            return; // Promise.reject(error);
          default:
            return Promise.reject(error);
        }
      } else {
        return Promise.reject(error);
      }
    }
  );



/* iAxios.interceptors.response.use(response => response,error => {
  console.log("response");
      if (error.response.status === 401) {
        // Fehler ignorieren und eigene Fehlermeldung anzeigen
        console.log("Unauthorisiert: Bitte melden Sie sich an.");
      }
      return Promise.reject(error);
    }
  ); */




export default fAxios;