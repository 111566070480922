<template>
  <div id="app">
    <Button @click="showOverlayPanel" icon="pi pi-pencil" outlined label="Unterschrift hinterlegen" class="text-xs"/>
    <OverlayPanel ref="opSignRef" appendTo="body" showCloseIcon :dismissable="false">
      <div class="flex flex-column align-items-center" style="flex: 1">
        <div class="flex gap-1">
          <div class="">
            <Button @click="clear" label="Löschen" class="p-button-danger" />
            <Button @click="undo" label="Rückgängig" class="p-button-secondary" />
            <Button @click="save" label="Abschicken" class="p-button-success" />
            <div>
              <VueSignaturePad id="signature" width="30vw md:w-96" height="20vh" ref="signaturePad" :options="options" />
            </div>
            <div class="line-1"></div>
          </div>
        </div>
      </div>
    </OverlayPanel>
  </div>
</template>
  
<script setup>
import { ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import fAxios from "@/store/axiosInterface";
const emit = defineEmits(['image-uploaded']);

const opSignRef = ref();
const toast = useToast();
const options = ref({
  penColor: "#003399",

});

const showOverlayPanel = (event) => {
  opSignRef.value.toggle(event);
};

const signaturePad = ref(null);

const undo = () => {
  if (signaturePad.value) {
    signaturePad.value.undoSignature();
  }
};

const clear = () => {
  if (signaturePad.value) {
    signaturePad.value.clearSignature();
  }
};

const dataURLtoBlob = (dataURL) => {
  const arr = dataURL.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

const cropImage = (image) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  // Bild auf dem Canvas zeichnen
  canvas.width = image.width;
  canvas.height = image.height;
  context.drawImage(image, 0, 0);

  // Zuschneiden auf das eigentliche Bild
  const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
  const { data, width, height } = imageData;
  let minX = width;
  let minY = height;
  let maxX = 0;
  let maxY = 0;

  // Ermitteln der tatsächlichen Bildgrenzen
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const index = (y * width + x) * 4;
      const alpha = data[index + 3];
      if (alpha > 0) {
        minX = Math.min(minX, x);
        minY = Math.min(minY, y);
        maxX = Math.max(maxX, x);
        maxY = Math.max(maxY, y);
      }
    }
  }

  // Größe des zugeschnittenen Bildes berechnen
  const croppedWidth = maxX - minX + 1;
  const croppedHeight = maxY - minY + 1;

  // Zugeschnittenes Bild auf einem neuen Canvas zeichnen und transparente Bereiche in Weiß umwandeln
  const croppedCanvas = document.createElement('canvas');
  const croppedContext = croppedCanvas.getContext('2d');
  croppedCanvas.width = croppedWidth;
  croppedCanvas.height = croppedHeight;
  croppedContext.fillStyle = 'white'; // Transparente Bereiche in Weiß umwandeln
  croppedContext.fillRect(0, 0, croppedWidth, croppedHeight);
  croppedContext.drawImage(canvas, minX, minY, croppedWidth, croppedHeight, 0, 0, croppedWidth, croppedHeight);

  // Gecropptes Bild als Base64-Daten-URL erhalten
  const croppedDataURL = croppedCanvas.toDataURL();

  return croppedDataURL;
};


const save = async () => {
  if (signaturePad.value) {
    const { isEmpty, data } = signaturePad.value.saveSignature();
    if (isEmpty) {
      alert("Bitte vorerst unterschreiben.");
    } else {
      const image = new Image();
      image.src = data;
      image.onload = async () => {
        const croppedDataURL = cropImage(image);
        const file = dataURLtoBlob(croppedDataURL);

        console.log("🚀 ~ file: HandSign.vue:128 ~ file:", file)

        await Base64Uploader(file);
      };
    }
  }
};


const onUpload = () => {
  toast.add({
    severity: 'success',
    summary: 'Unterschrift',
    detail: 'Unterschrift erfolgreich gespeichert',
    life: 3000
  });
  opSignRef.value.hide();
  emit("signature-updated");
};

const Base64Uploader = async (file) => {
const reader = new FileReader();
reader.onloadend = async function () {
  const base64Data = reader.result;
  const formData = new FormData(); // Erstellen Sie ein neues FormData-Objekt
  formData.append('filedata', file); // Fügen Sie die Datei zum FormData-Objekt hinzu
  formData.append('removebg_signature', false);
  try {
    const response = await fAxios.post('/company/signature', formData, {
      headers: {
        'Content-Type': 'multipart/form-data' // Stellen Sie sicher, dass Sie den richtigen Content-Type festlegen
      }
    });
    onUpload();
  } catch (error) {
    console.error(error);
  }
};
const fileURL = URL.createObjectURL(file);
let blob = await fetch(fileURL).then((r) => r.blob());
reader.readAsDataURL(blob);
};

</script>
  