<template>
  <template v-if="!store.rules.activatedModules.accounting">
  <UpgradeLicence />
    </template>
  <template v-else>
  <div>
    <ConfirmDialog group="userDelete">
      <template #container="{ message, acceptCallback, rejectCallback }">
        <div
          class="flex flex-column align-items-center p-5 surface-overlay border-round">
          <div
            class="border-circle bg-red-500 inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
            <i class="pi pi-trash text-5xl text-white"></i>
          </div>
          <span class="font-bold text-2xl block mb-2 mt-4">{{
            message.header
          }}</span>
          <p class="mb-0">
            Möchten Sie
            <span class="font-bold">{{ message.user }}</span> wirklich
            entfernen?
          </p>
          <p>
            Geben Sie aus Sicheheitsgründen den Nachnamen des zu löschenden
            Benutzers und die Pin
            <span class="font-bold">{{ confirmPin }}</span> ein.
          </p>
          Nachname: <InputText v-model="confirmSurName" class="w-12rem" />
          <p></p>
          Pin:
          <div class="card flex justify-content-center">
            <InputOtp v-model="confirmedPin" integerOnly />
          </div>
          <div class="flex align-items-center gap-2 mt-4">
            <Button
              label="Ja"
              outlined
              severity="danger"
              @click="acceptCallback"
              class="w-8rem"></Button>
            <Button
              label="Nein"
              outlined
              @click="rejectCallback"
              class="w-8rem"></Button>
          </div>
        </div>
      </template>
    </ConfirmDialog>
    <Card>
      <template #title class="p-card"> </template>
      <template #content>
        <MultiSelect
          :modelValue="selectedColumns"
          :options="optColumns"
          optionLabel="header"
          @update:modelValue="onToggle"
          display="chip"
          placeholder="Spalten Ansicht wählen"
          class="w-full" />
        <DataTable
          v-model:filters="filters"
          :value="employees"
          editMode="row"
          scrollable
          scrollHeight="85vh"
          filterDisplay="row"
          tableStyle="min-width: 10%"
          resizableColumns
          columnResizeMode="fit"
          stripedRows
          selectionMode="single"
          dataKey="email"
          sortField="email"
          :sortOrder="-1"
          class="p-datatable-sm"
          @row-dblclick="openUserDialog"
          :virtualScrollerOptions="{
            lazy: false,
            itemSize: 46,
            showLoader: true,
            numToleratedItems: 100,
          }"
          ref="dataTableRef">
          <Column
            headerStyle="width: 2rem; text-align: center"
            bodyStyle="text-align: center; overflow: visible">
            <template #header>
              <div>
                <div>
                  <Button
                    type="button"
                    outlined
                    id="isNew"
                    :pt="{ icon: { class: 'text-xl' } }"
                    icon="pi pi-plus"
                    @click="openUserDialog"
                    v-tooltip="'Neuen Benutzer erstellen'" />
                </div>
              </div>
            </template>
            <template #body="{ data }">
              <Button
                type="button"
                :pt="{ icon: { class: 'text-xl' } }"
                id="editUser"
                text
                icon="pi pi-pencil"
                aria-haspopup="true"
                aria-controls="overlay_menu"
                rounded
                @click="popUptoggle($event, data)"
                v-tooltip="'Aktionen'" />
              <Menu
                ref="menu"
                id="overlay_menu"
                :model="popUpitems"
                :popup="true" />
            </template>
          </Column>

          <Column
            v-for="col of staticColumns"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            :footer="col.header"
            :showFilterMenu="false"
            sortable
            :sortField="col.field">
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                v-tooltip.top.focus="'Zum Anwenden Enter drücken'"
                type="text"
                @keydown.enter="filterCallback()"
                @input="filterModel.value || filterCallback()"
                class="p-column-filter" />
            </template>
            <template #body="{ data, field }">
              {{ data[field] }}
            </template>
            <template #loading>
              <Skeleton width="60%" height="2rem" />
            </template>
          </Column>

          <Column
            v-for="col of selectedColumns"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            :footer="col.header"
            :showFilterMenu="false"
            sortable
            :sortField="isSortable(col.field) ? col.field + '_us' : col.field">
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                v-tooltip.top.focus="'Zum Anwenden Enter drücken'"
                type="text"
                @keydown.enter="filterCallback()"
                @input="filterModel.value || filterCallback()"
                class="p-column-filter" />
            </template>
            <template #body="{ data, field }">
              <span v-if="field == 'role'">
                {{ rolleLabel(data[field]) }}
              </span>
              <span v-else>
                {{ data[field] }}
              </span>
            </template>
            <template #loading>
              <Skeleton width="60%" height="2rem" />
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>
</template>
</template>

<script setup>
import { reactive, computed, ref, onMounted, defineAsyncComponent } from "vue";
import { useToast } from "primevue/usetoast";
import { userStore } from "@/store/userStore";
import { storeToRefs } from 'pinia';
import ChangePasswordForm from "@/api/ChangePasswordForm.vue";
import { useRouter } from "vue-router";
import iAxios from "@/store/axiosInterface";
import { FilterMatchMode } from "primevue/api";
import { useDialog } from "primevue/usedialog";
import { useConfirm } from "primevue/useconfirm";
import * as calcUtils from "@/utils/calculationUtils.js";

const confirmPin = ref();
const confirmedPin = ref();
const confirmSurName = ref();
const isMobile = ref(false);
const employees = ref([]);

const dataTableRef = ref();
const confirm = useConfirm();
const dialog = useDialog();
const store = userStore();
const moreinfos = ref(false);
const changePassword = ref(false);
const warnExistSlug = ref(false);
const toast = useToast();
const router = useRouter();
const UserSettingsForm = defineAsyncComponent(() =>
  import("@/views/UserSettingsForm.vue")
);
const rolleLabel = (e) => {
  return calcUtils.rolleRead.value.find((r) => r.optionValue === e).optionLabel;
};
const isSortable = (field) => {
  return field === "lastSeen" || field === "created";
};

const openUserDialog = async (event) => {
  const isNew = !event.data;
  if (store.debug)
    console.log(
      "🚀 ~ file: UserSettingsView.vue:199 ~ event.data:",
      event.data
    );
  const dialogRef = dialog.open(UserSettingsForm, {
    props: {
      header: isNew ? "Benutzer erstellen" : "Benutzer bearbeiten",
      style: {
        width: "45vw",
      },
      breakpoints: {
        "1200px": "75vw",
        "640px": "100vw",
      },
      modal: true,
    },
    data: {
      isNew: isNew,
      benutzerDaten: event.data,
    },
    onClose: async (options) => {
      const buttonType = options?.data?.buttonType;
      if (options.data) {
        if (buttonType == "Save") {
          saveUser(options.data);
        }
      }
    },
  });
};

const saveUser = async (data) => {
  try {
    if (data.isNew) {
      const response = await iAxios
        .post(
          process.env.VUE_APP_BASE_API_URL + "/company/sub-register",
          data.userData
        )
        .then((res) => {
          console.log("🚀 ~ file: UserSettingsView.vue:259 ~ res:", res);

          if (res.status == 201) {
            employees.value = [...employees.value, data.userData]; // Array neu zuweisen
          }

          console.log(
            "🚀 ~ file: UserSettingsView.vue:264 ~ employees.value:",
            employees.value
          );
        });
      toast.add({
        severity: "success",
        summary: "Erfolg",
        detail: "Benutzerdaten erfolgreich angelegt",
        life: 2000,
      });
    } else {
      data.userData.subUid = data.userData.cid;
      //nachdem patch werden die werte in emplyess aktualisiert
      const res = await iAxios
        .patch(
          process.env.VUE_APP_BASE_API_URL + "/company/sub-modify",
          data.userData
        )
        .then((res) => {
          if (res.status == 203) {
            const index = employees.value.findIndex(
              (e) => e.cid === data.userData.cid
            );
            employees.value[index] = data.userData;
          }
        });
      toast.add({
        severity: "success",
        summary: "Erfolg",
        detail: "Benutzerdaten erfolgreich aktualisiert",
        life: 2000,
      });
    }
  } catch (error) {
    if (error?.response?.status == 409) {
      toast.add({
        severity: "error",
        summary: "Fehler",
        detail:
          "Diese Emailadresse kann nicht verwendet werden, bitte nehmen Sie eine andere Emailadresse.",
        life: 8000,
      });
    } else {
      console.error("🚀 ~ file: UserSettingsView.vue:309 ~ error:", error);
      toast.add({
        severity: "error",
        summary: "Eingabefehler",
        detail: error.message,
        life: 3000,
      });
    }
  }
};

const staticColumns = ref([
  { field: "firstName", header: "Vorname" },
  { field: "surName", header: "Nachname" },
]);

const optColumns = ref([
  { field: "email", header: "Email-Adresse" },
  { field: "mobile", header: "Mobilnummer" },
  { field: "role", header: "Rolle" },
  { field: "created_de", header: "Erstelldatum" },
  { field: "lastSeen_de", header: "Zuletzt verwendet" },
]);

const startColumns = ref([
  { field: "email", header: "Email-Adresse" },
  { field: "mobile", header: "Mobilnummer" },
  { field: "role", header: "Rolle" },
]);
const selectedColumns = ref(startColumns.value);

const onToggle = (val) => {
  const valFields = val.map((v) => v.field);
  optColumns.value.forEach((col) => {
    if (
      valFields.includes(col.field) &&
      !selectedColumns.value.some(
        (selectedCol) => selectedCol.field === col.field
      )
    ) {
      selectedColumns.value.push(col);
    } else if (!valFields.includes(col.field)) {
      selectedColumns.value = selectedColumns.value.filter(
        (selectedCol) => selectedCol.field !== col.field
      );
    }
  });
};

const filters = ref({
  firstName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  surName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  email: { value: null, matchMode: FilterMatchMode.CONTAINS },
  role: { value: null, matchMode: FilterMatchMode.CONTAINS },
  mobile: { value: null, matchMode: FilterMatchMode.CONTAINS },
  created_de: { value: null, matchMode: FilterMatchMode.CONTAINS },
  lastSeen_de: { value: null, matchMode: FilterMatchMode.CONTAINS },
  customerId: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

let rowData = null;

const popUpLabel = reactive({ label: "" });

const popUptoggle = (event, data) => {
  rowData = data;
  popUpLabel.label = rowData.firstName + " " + rowData.surName;
  menu.value.toggle(event);
};
const selectedUser = ref();
const menu = ref();
const popUpitems = computed(() => [
  {
    label: popUpLabel.label,
    items: [
      {
        label: "Bearbeiten",
        icon: "pi pi-user-edit",
        command: () => {
          if (rowData) {
            openUserDialog({ data: rowData });
          }
        },
      },
      { separator: true },
      {
        label: "Entfernen",
        icon: "pi pi-trash text-red-500",
        style: "color: red!important;",
        command: () => {
          if (rowData) {
            console.log(
              "🚀 ~ file: UserSettingsView.vue:351 ~ rowData:",
              rowData
            );

            confirmDelete(rowData);
          }
        },
      },
    ],
  },
]);

const sendAccessToUser = (data) => {
  toast.add({
    severity: "success",
    summary:
      "göya Zugangsdaten gesendet für " + data.firstName + " " + data.surName,
    life: 3000,
  });
};

function generatePin() {
  return Math.floor(Math.random() * 9000) + 1000;
}

const confirmDelete = (data) => {
  confirmPin.value = generatePin();
  confirm.require({
    group: "userDelete",
    user: data.firstName + " " + data.surName,
    header: "Benutzer entfernen bestätigen",
    icon: "pi pi-trash text-red-500",
    acceptClass: "p-button-danger",
    acceptLabel: "Ja",
    rejectLabel: "Nein",
    defaultFocus: "reject",
    accept: () => {
      const pinCorrect = confirmPin.value == confirmedPin.value;
      const surNameCorrect = confirmSurName.value === data.surName;
      if (pinCorrect && surNameCorrect) {
        console.log("🚀 ~ file: UserSettingsView.vue:355 ~ data:", data);

        iAxios
          .delete(process.env.VUE_APP_BASE_API_URL + "/company/sub-delete", {
            data: { subUid: data.cid },
          })
          .then((res) => {
            if (res.status == 200) {
              employees.value.pop(data);
            }
          });

        toast.add({
          severity: "info",
          summary:
            "Entfernen von > " +
            data.firstName +
            " " +
            data.surName +
            " < Bestätigt",
          detail: "Benutzer wurde entfernt",
          life: 3000,
        });
      } else {
        confirmSurName.value = "";
        confirmedPin.value = "";
        toast.add({
          severity: "error",
          summary:
            "Fehler beim Entfernen von " + data.firstName + " " + data.surName,
          detail:
            (!pinCorrect ? " Pin fehlerhaft" : "") +
            (!surNameCorrect ? " Nachname fehlerhaft" : ""),
          life: 3000,
        });
      }
    },
    reject: () => {
      toast.add({
        severity: "error",
        summary: "Abgebrochen",
        detail: "Entfernen abgebrochen",
        life: 3000,
      });
    },
  });
};

// Formulardaten
const formData = reactive({
  role: "management",
  firstName: "Max Moritz",
  surName: "Mustermann",
  mobile: "+49 176 12345678",
  email: "max.mustermann@moritz.dom",
});

async function werteLaden() {
  const res = await iAxios.get(
    process.env.VUE_APP_BASE_API_URL + "/company/sub-list"
  );

  console.log("🚀 ~ file: UserSettingsView.vue:400 ~ res:", res.data.subs);
  if (res.data.subs) employees.value = await res.data.subs;

  console.log(
    "🚀 ~ file: UserSettingsView.vue:405 ~ employees.value:",
    employees.value
  );
}

const checkMobile = () => {
  isMobile.value = window.innerWidth < 768;
  return isMobile.value;
};

onMounted(() => {
  if (checkMobile()) {
    selectedColumns.value = [];
  }
  werteLaden();
});
</script>
<style scoped>
.p-card {
  text-align: left !important;
}

.p-card-title {
  font-size: large !important;
}
</style>
