<template>
    <div class="card">
        <TabView>
            <TabPanel header="Dokumenteneinstellungen">
                <DocumentSettings />
            </TabPanel>
            <TabPanel header="Schnellstart & Tipps">
                <InitialSettings />
            </TabPanel>
            <TabPanel header="Versanddienste">
                <ParcelServicesView/>
            </TabPanel>
            <TabPanel header="Shopify" v-if="store?.whoAmIData?.rules?.activatedModules?.shopify">
                <ShopifySettingsView/>
            </TabPanel>
            <TabPanel v-if="store.debug" header="DB-Tools">
                <DbTools/>
            </TabPanel>
        </TabView>
    </div>
</template>

<script setup>
import InitialSettings from './InitialSettings.vue';
import DocumentSettings from './DocumentSettings.vue';
import ParcelServicesView from './ParacelServicesView.vue';
import ShopifySettingsView from './ShopifySettingsView.vue';
import DbTools from './DbTools.vue';
import { userStore } from '@/store/userStore';
const store = userStore();
</script>