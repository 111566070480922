<template>
  <div>
    <!-- PrimeVue FileUpload -->
    <FileUpload ref="fileUpload" :multiple="false" mode="advanced" accept=".csv"
      :pt="{ thumbnail: { src: require('@/assets/csv-file-48.png') } }" customUpload @uploader="onFileChange">
      <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
        <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
          <div class="flex gap-2">
            <Button @click="chooseCallback()" rounded outlined><span class="pi pi-cloud-upload pr-2"></span>Datei
              Wählen</Button>
            <Button @click="uploadEvent(uploadCallback)" rounded outlined severity="success"
              :disabled="!files || files.length === 0"><span class="pi pi-play pr-2"></span>Laden</Button>
            <Button @click="clearCallback()" rounded outlined severity="danger" :disabled="!files || files.length === 0">
              <span class="pi pi-times pr-2"></span>Abbrechen
            </Button>
            <Button @click="showPreview" rounded outlined :loading="loading" severity="success"
              :disabled="!parsed || !(assignedDbColumns.length > 0)"><span class="pi pi-eye pr-2"></span>Vorschau</Button>
            <Button @click="saveToBackend" rounded severity="success"
              :disabled="!parsed || !(assignedDbColumns.length > 0)"><span
                class="pi pi-save pr-2"></span>Speichern</Button>
          </div>
          <ProgressBar :value="totalSizePercent" :showValue="false"
            :class="['md:w-20rem h-1rem w-full md:ml-auto', { 'exceeded-progress-bar': totalSizePercent > 100 }]"><span
              class="white-space-nowrap">{{ totalSize }}B / 1Mb</span></ProgressBar>
        </div>
      </template>
    </FileUpload>
    <!-- PrimeVue DataTable -->
    <DataTable :value="dbColumns" v-if="showDataTable">
      <!-- Render columns and dropdowns -->
      <Column field="dbField" header="DB Feld" >
        <template #body="{ data }">
          <span :class="['w-full', 'md:w-14rem', {'p-error': (data.required && !(data.dropdownValue || data.inputTextValue)) }]">{{ data.label }}</span>
        </template>
      </Column>

      <Column field="csvField" header="CSV Feld">
        <template #body="{ data, field }">
          <span class="p-float-label">
            <Dropdown v-model="data.dropdownValue" filter showClear :ref="ref => dropdownRefs.push(ref)"
              :options="csvColumns" optionLabel="label" optionValue="value" @change="onDropdownChange(data, $event)"
              class="w-full md:w-14rem"
              >
            </Dropdown>

            <label for="'dd'+field">Feldname wählen</label>
          </span>
        </template>
      </Column>

      <Column field="defaultValue" header="Füllwert" :key="dbColumns.key">
        <template #body="{ data, field }">
          <InputText v-if="data.allowDefaultValue" v-model="data.inputTextValue" @change="updateAssignedDbColumns"
            v-show="!(data.dropdownValue !== null && data.dropdownValue !== undefined && data.dropdownValue !== '')"
            placeholder="Bsp. true/false/'Wert'"/>
        </template>
      </Column>

    </DataTable>
    <!-- PrimeVue DynamicDialog for Preview -->
    <Dialog v-model:visible="previewMode" maximizable @hide="hidePreviewDialog" :style="{ width: '50%' }"
      ref="previewDialog">
      <template #header>Vorschau</template>
      <!-- Render table for CSV data preview -->
      <DataTable v-if="csvData.length" :value="csvData">
        <!-- Render columns dynamically based on assigned dbColumns with CSV field assignment -->
        <Column v-for="column in assignedDbColumns" :key="column.key"
          :field="column.dropdownValue || column.inputTextValue" :header="column.label">
          <template #body v-if="column.inputTextValue">
            {{ column.inputTextValue }}
          </template>
        </Column>
      </DataTable>
    </Dialog>
  </div>
</template>

<script setup>
import { userStore } from "@/store/userStore";
import { storeToRefs } from 'pinia';
import { ref, onMounted, getCurrentInstance } from 'vue';
import { useToast } from "primevue/usetoast";
import iAxios from "@/store/axiosInterface";

const toast = useToast();

const store = userStore();
// Data
const csvData = ref([]);
const csvHeaders = ref([]);
const csvColumns = ref([]);
const showDataTable = ref(false);
const previewMode = ref(false);
const internalInstance = getCurrentInstance();
const $papa = internalInstance.appContext.config.globalProperties.$papa;
const parsed = ref(false);
const totalSize = ref(0);
const totalSizePercent = ref(0);
const files = ref([]);
const assignedDbColumns = ref([]);
const loading = ref(false);
const dropdownRefs = ref([]);
const previewDialog = ref();
// Method to handle CSV file change
const onFileChange = async (event) => {
  const file = event.files[0]; // Assume only one file is selected

  // Parse the CSV file using vuepapa
  try {
    await $papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        csvData.value = results.data;
        parsed.value = true;
        csvHeaders.value = results.meta.fields;
        csvColumns.value = results.meta.fields.map(header => ({ label: header, value: header }));
        showDataTable.value = true;
        // Check if dropdownRefs.value is defined before pushing to it
        clearDropdownSelections();
      },
    });
  } catch (error) {
    console.error('Error parsing CSV file:', error);
  }
};

const uploadEvent = (callback) => {
  totalSizePercent.value = totalSize.value / 10;
  callback();
};

const updateAssignedDbColumns = () => {
  //  hier muss um inputtext falls wert hat ergänzt werden
  assignedDbColumns.value = props.dbColumns.filter(column => column.dropdownValue || column.inputTextValue);

};

const onDropdownChange = (data, event) => {
  data.inputTextValue = undefined;
  updateAssignedDbColumns();
};

const clearDropdownSelections = () => {
  if (dropdownRefs.value.length > 0) {
    dropdownRefs.value.forEach(dropdown => {
      dropdown.updateModel(null);
    });
  }
};


const showPreview = () => {
  previewMode.value = true;
};

const hidePreviewDialog = () => {
  previewMode.value = false;
};

const saveToBackend = (save) => {
  const invalidFields = getInvalidFields();

  if (invalidFields.length === 0) {
    const data4DB = convertToJsonObject();

    if (data4DB) {
      if (props.dataSendKind === 'row') {
        for (const row of data4DB) {
          if (save === 'save') iAxios.post(props.ep, row);
          else console.log(row);
        }
      } else if (props.dataSendKind === 'full') {
        // Schicke das gesamte JSON-Objekt an den Endpunkt
        if (save === 'save') iAxios.post(props.ep, data4DB);
        else console.log(data4DB);
      } else {
        throw new Error(`Unknown dataSendKind: ${props.dataSendKind}. Only 'row' or 'full' are allowed.`);
      }
    }
  } else {
    showInvalidFieldsToast(invalidFields);
  }
};

const getInvalidFields = () => {
  const invalidFields = [];

  for (const column of props.dbColumns) {
    if (column.required && !(column.inputTextValue || column.dropdownValue)) {
      invalidFields.push(column.label); // Pflichtfeld ohne Wert gefunden
    }
  }

  return invalidFields;
};

const showInvalidFieldsToast = (invalidFields) => {
  const message = `Folgende Pflichtfelder sind nicht ausgefüllt: ${invalidFields.join(', ')}`;
  toast.add({
    severity: 'error',
    summary: 'Fehler beim Speichern',
    detail: message,
    life: 5000 // Dauer der Toast-Meldung in Millisekunden
  });
};

// Define props
/* 
Beispiel wenn eingesetzt wird
<CsvImport :ep="'/company/items'" :dataSendKind="'full'" :preview=true :dbColumns="dbColumns"></CsvImport>

const dbColumns = ref([
  { key: 'itemName', label: 'Artikelname', required: true, allowDefaultValue: true },
  { key: 'itemAlias', label: 'Alias' },
  { key: 'showAlias', label: 'Verwende Alias', allowDefaultValue: true },
  { key: 'itemInPrice', label: 'Einkaufspreis' },
  { key: 'itemOutPrice', label: 'Verkaufspreis', required: true }
]);
key=Datenbankfeld
label=angezeigter Name
required=Pflichtfeld default wert ist false bei nicht angabe
allowDefaultValue= Erlaubt einen eigenen Wert zu definieren, default ist false
*/
const props = defineProps({
  getEP: {
    type: String,
    required: true
  },
  postEP: {
    type: String,
    required: true
  },
  dataSendKind: {
    type: String,
    required: false,
    default: 'row'
  },
  preview: {
    type: Boolean,
    default: true
  },
  dbColumns: Array,
  required: true,
});

const convertToJsonObject = () => {
  if (csvData.value.length > 0 && assignedDbColumns.value.length > 0) {
    const jsonObject = csvData.value.map(row => {
      const jsonRow = {};
      assignedDbColumns.value.forEach(column => {
        const csvField = column.dropdownValue;
        const dbKey = column.key;
        const defaultValue = column.inputTextValue || ''; // Wert von inputTextValue, falls vorhanden

        jsonRow[dbKey] = defaultValue !== '' ? defaultValue : row[csvField]; //  Wert von inputTextValue, wenn vorhanden, sonst den CSV-Wert
      });
      return jsonRow;
    });

    console.log('JSON Object:', jsonObject);
    return jsonObject;
  } else {
    console.warn('No Data Lan!');
    return null;
  }
};

onMounted(() => {
});
</script>
