import { defineStore } from "pinia";
import iAxios from "./axiosInterface";
import { ref, reactive } from "vue";

export const userStore = defineStore("userStore", {
  state: () => ({
    standalone: undefined,
    headerTitel: "",
    cid: null,
    whoAmIData: {},
    isMainUser: false,
    isManagementUser: false,
    isAssistantUser: false,
    isWorkerUser: false,
    isStuffUser: false,
    offerEditRights: false,
    loggedUserName: "",
    dashboardRights: true,
    loggedIN: false,
    expired: false,
    registrationConfirmed: false,
    firstLogin: null,
    debug: false,
    dbgUser: false,
    registersStayOpen: false,
    tipEnabledList: {},
    companySettings: {},
    holdSidebarOpen: false,
    showVkSideDiv: false,
    vkMargen: [0, 0, 0, 0, 0, 0, 0, 0, 0], // 0-8
    items: [],
    apiKeyStorage: null,
    itemEditSettings: { erweitert: false },
    itemsLoaded: false,
    rules: {
      activatedModules: {
        dashboard: true,
        catalog: true,
        customer: true,
        offer: true,
        order: true,
        invoice: true,
        creditNote: false,
        businessLetter: true,
        shipping: true,
        picking: true,
        inventory: true,
        stock: true,
        settings: true,
        reports: false,
        employees: false,
        projects: false,
        tasks: false,
        calendar: false,
        chat: false,
        notes: false,
        documents: false,
        email: true,
        sms: false,
        push: false,
        payment: true,
        shop: true,
        accounting: true,
        help: true,
        support: true,
      },
    },
    inventoryData: {
      type: "STOCKINVENTORY",
      itemList: [],
      docContent: {
        notes: null,
      },
    },
  }),

  getters: {
    getItems(state) {
      return state.items;
    },
    getDebug(state) {
      return state.debug;
    },
    getWhoAmIData(state) {
      return state.whoAmIData;
    },
    getCategories(state) {
      if (!state.companySettings.categories?.list) {
        state.companySettings.categories = {
          list: [],
        };
      }
      return state.companySettings.categories.list;
    },
    getCompanyTips(state) {
      return state.tipEnabledList;
    },
    isAuthenticated(state) {
      return !!state.cid;
    },
    isFirstLogin(state) {
      return state.firstLogin;
    },
  },

  actions: {
    setRegistrationConfirmed(bool) {
      this.registrationConfirmed = bool;
    },
    setDebug(bool) {
      this.debug = bool;
    },
    async setCategories(categories) {
      try {
        this.categories = { list: { categories: categories } };

        console.log("🚀 ~ categories:", categories);

        const url = process.env.VUE_APP_BASE_API_URL + '/company/settings';
        const formData = { categories: { list: categories } };
        const res = await iAxios.put(url, formData);

        console.log("🚀 ~ res:", res);

        return res;
      } catch (e) {
        console.error(e);
      }
    },
    async setCompanyTips(tipEnabledList) {

      console.log("🚀 ~ file: userStore.js:128 ~ tipEnabledList:", tipEnabledList)

      try {
        let url = process.env.VUE_APP_BASE_API_URL + "/company/tips";
        const response = await iAxios.post(url, {tipsList:tipEnabledList});
        const tips = {};
        tips.tipsList = response.data;
        return tips;
      } catch (e) {
        console.error(e);
      }
    },
    async resetPassword(emailAddress4ResetPassword) {
      let url = process.env.VUE_APP_BASE_API_URL + "/reset-pass/" + emailAddress4ResetPassword;
      try {
        const response = await iAxios.get(url);
        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        if (error.response.status == 403) {
          return error.response;
        }
      }
    },
    async logout(justReset) {
      this.cid = null;
      this.expired = true;
      this.loggedIN = false;
      this.whoAmIData = null;
      this.loggedUserName = "";

      if (justReset) return;
      let url = process.env.VUE_APP_BASE_API_URL + "/logout";
      try {
        await iAxios.get(url);
      } catch (error) {
        console.error("Logout failed:", error);
      }
    },
    async auth(payload) {
      let url = "";
      if (payload.mode === "signin") {
        url = process.env.VUE_APP_BASE_API_URL + "/login";
      } else if (payload.mode === "signup") {
        url = process.env.VUE_APP_BASE_API_URL + "/register";
      } else {
        return;
      }
      const authDO = {
        email: payload.email,
        password: payload.password,
      };
      try {
        const response = await iAxios.post(url, authDO);
        this.cid = response.data.cid;
        this.firstLogin = response.data.firstLogin;
        return response;
      } catch (error) {
        const errorMessage = new Error(
          error.response.data.message || "UNKNOWN_ERROR"
        );
        throw errorMessage;
      }
    },
    async signup(payload) {
      const signupDO = {
        ...payload,
        mode: "signup",
      };
      try {
        const response = await this.auth(signupDO);
        this.cid = response.data.cid;
        return response;
      } catch (error) {
        throw error;
      }
    },
    async signin(payload) {
      const signinDO = {
        ...payload,
        mode: "signin",
      };
      try {
        const response = await this.auth(signinDO);
        this.cid = response.data.cid;
        this.firstLogin = response.data.firstLogin;
        await this.whoAmI(); // Fetch user data after login
        const tipsResponse = await iAxios.get("/company/tips");
        this.tipEnabledList = tipsResponse?.data?.tipsList || reactive({ tipsList: { fastStartVisible: true } });

        return response;
      } catch (error) {
        throw error;
      }
    },
    setExpired(bool) {
      this.expired = bool;
      if (bool == true) this.loggedIN = false;
    },
    setHeaderTitel(titelText) {
      this.headerTitel = titelText;
    },
    setItems(items) {
      console.log("setItems");
      this.items = JSON.parse(JSON.stringify(items));
    },
    async whoAmI() {
      let url = process.env.VUE_APP_BASE_API_URL + "/whoami";
      try {
        const response = await iAxios.get(url);
        this.whoAmIData = response.data;
        this.rules.activatedModules = response.data.rules.activatedModules;
        this.loggedUserName = response.data.userName;
        this.isMainUser = response.data.role === "ADMIN";
        this.isManagementUser = response.data.isMain || response.data.role === "MANAGEMENT" || response.data.role === "ADMIN";
        this.isAssistantUser = response.data.role === "ASSISTANT";
        this.isStuffUser = response.data.role === "STUFF";
        this.isWorkerUser = response.data.role === "WORKER";
        this.offerEditRights = this.isMainUser || this.isManagementUser || this.isAssistantUser;
        this.dashboardRights = this.isMainUser || this.isManagementUser;
        this.cid = response.data.cid;
        this.firstLogin = response.data.firstLogin;
        await this.getCompanySettings();
        return response.data;
      } catch (error) {
        this.cid = null;
        this.loggedIN = false;
        this.expired = true;
      }
    },
    async getCompanySettings() {
      try {
        const res = await iAxios.get(process.env.VUE_APP_BASE_API_URL + '/company/settings');
        this.companySettings = res.data;
        return res.data;
      } catch (exception) {
        console.log(exception);
      }
    },
    async refreshUserData() {
      try {
        await this.whoAmI(); // Reloads the user data
        console.log("Data reloaded successfully.");
      } catch (error) {
        console.error("Error reloading data:", error);
      }
    },
  },
  persist: true,
});

/* import { defineStore } from "pinia";
import iAxios from "./axiosInterface";
import { ref, reactive } from "vue";

export const userStore = defineStore("userStore", {
  state: () => ({
    headerTitel: "",
    cid: null,
    whoAmIData: {},
    isMainUser: false,
    loggedUserName: "",
    isStuffUser: false,
    isWorkerUser: false,
    isManagementUser: false,
    loggedIN: false,
    expired: false,
    registrationConfirmed: false,
    firstLogin: null,
    debug: false,
    registersStayOpen: false,
    tipEnabledList:{},
    companySettings: {},
    holdSidebarOpen: false,
    showVkSideDiv:false,
    vkMargen:[0,0,0,0,0,0,0,0,0], //0-8
    items: [],
    apiKeyStorage:null,
    itemEditSettings: {erweitert: false},
    itemsLoaded: false,
    rules: {
      activatedModules: {
        dashboard: true,
        catalog: true,
        customer: true,
        offer: true,
        order: true,
        invoice: true,
        creditNote: false,
        businessLetter: true,
        shipping: true,
        picking: true,
        inventory: true,
        stock: true,
        settings: true,
        reports: false,
        employees: false,
        projects: false,
        tasks: false,
        calendar: false,
        chat: false,
        notes: false,
        documents: false,
        email: true,
        sms: false,
        push: false,
        payment: true,
        shop: true,
        accounting: true,
        help: true,
        support: true,
      }
    },
        
    //expiresIn: "",
     inventoryData : {
      type: "STOCKINVENTORY",
      itemList: [],
      docContent: {
        notes: null,
      },
    },
  }),


  getters: {
    getItems(state) {
      return state.items;
    },

    getDebug(state) {
      return state.debug;
    },

    getWhoAmIData(state) {
      this.whoAmI;
      return state.whoAmIData;
    },

    getCategories(state) {
      if (!state.companySettings.categories?.list){
        state.companySettings.categories = {
          list: [],
        };
      }
      return state.companySettings.categories.list;
    },

    async whoAmI(state) {

      // console.log("🚀 ~ file: userStore.js:65 ~ state:", state)

      console.log("whoAmI")
      let url = process.env.VUE_APP_BASE_API_URL + "/whoami";
      try {
        const response = await iAxios.get(url);
        state.whoAmIData = response.data;
        state.isMainUser = response.data.role=="ADMIN";
        state.isStuffUser = response.data.role=="STUFF";
        state.isWorkerUser = response.data.role=="WORKER";
        state.isManagementUser = response.data.isMain || response.data.role=="MANAGEMENT";

        // console.log("🚀 ~ file: userStore.js:61 ~ response.data:", response.data)

        state.cid = response.data.cid;
        state.firstLogin = response.data.firstLogin;
        //if (response.data.firstLogin) await iAxios.get('https://storage.famtura.de/api/auth/apikey/add');
        this.getCompanySettings;
        return state;
      } catch (error) {
        state.cid = null;
        state.loggedIN = false;
        state.expired = true;
      }
    },

    getCompanyTips(){
      return this.tipEnabledList;
    },

    async getCompanySettings(state) {
      try {
        const res = await iAxios.get(process.env.VUE_APP_BASE_API_URL + '/company/settings');
        state.companySettings = res.data;
        return state.companySettings;
      } catch (exception) {
        console.log(exception);
      }
    },

    isAuthenticated(state) {
      return !!state.cid;
    },



    isFirstLogin(state) {
      return state.firstLogin;
    },
  },
  actions: {
    setRegistrationConfirmed(bool) {
      this.registrationConfirmed = bool;
    },
    setDebug(bool) {
      this.debug = bool;
    },
    async setCategories(categories) {
      try{
      this.categories = {list:{categories:categories}};

      // console.log("🚀 ~ file: userStore.js:100 ~ categories:", categories)

      const url = process.env.VUE_APP_BASE_API_URL + '/company/settings';
      const formData = {categories: {list:categories}};
      const res=await iAxios.put(url, formData);

      // console.log("🚀 ~ file: userStore.js:103 ~ res:", res)

      return res;
    }catch(e){
      console.error(e);
    }
    },

    async setCompanyTips(tipEnabledList) {
      try {
        let url = process.env.VUE_APP_BASE_API_URL + "/company/tips";
        const response = await iAxios.post(url, tipEnabledList);
        const tips={};
        tips.tipsList=response.data;
        return tips;
      } catch (e) {
      }
    },

    async resetPassword(emailAddress4ResetPassword) {
      //let url = process.env.VUE_APP_BASE_API_URL + "/reset-pass/" + emailAddress4ResetPassword+"/"+this.whoAmIData.cid;
      let url = process.env.VUE_APP_BASE_API_URL + "/reset-pass/" + emailAddress4ResetPassword;
      try {
        const response = await iAxios.get(url);
        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        if (response.status == 403) {
          return response;
        }
      }
    },
    async logout() {
      let url = process.env.VUE_APP_BASE_API_URL + "/logout";
      try {
        this.cid = null;
        this.expired = true;
        this.loggedIN = false;
        this.whoAmIData = null;
        await iAxios.get(url);
        //return response;
      } catch (error) {
        this.cid = null;
        this.expired = true;
        this.loggedIN = false;
        this.whoAmIData = null;

      }
    },
    async auth(payload) {
      let url = "";
      if (payload.mode === "signin") {
        url = process.env.VUE_APP_BASE_API_URL + "/login";
      } else if (payload.mode === "signup") {
        url = process.env.VUE_APP_BASE_API_URL + "/register";
      } else {
        return;
      }
      const authDO = {
        email: payload.email,
        password: payload.password,
      };
      try {
        const response = await iAxios.post(url, authDO);
        this.cid = response.data.cid;
        this.firstLogin = response.data.firstLogin;
        this.whoAmIData = this.whoAmI;
        return response;
      } catch (error) {
        const errorMessage = new Error(
          error.response.data.message || "UNKNOWN_ERROR"
        );
        throw errorMessage;
      }
    },
    async signup(payload) { //Register user
      const signupDO = {
        ...payload,
        mode: "signup",
      };
      try {
        const response = await this.auth(signupDO);
        this.cid = response.data.cid;
        return response;
      } catch (error) {
        //console.log("Error during signup: ", error);
        throw error;
      }
    },
    async signin(payload) { //Login
      const signinDO = {
        ...payload,
        mode: "signin",
      };
      try {
        const response = await this.auth(signinDO);
        this.cid = response.data.cid;
        this.firstLogin = response.data.firstLogin;
        await iAxios.get("/company/tips").then((res) => {
          this.tipEnabledList= (response.data?res.data:reactive({tipsList:{fastStartVisible:true}}));
        // console.log("🚀 ~ file: userStore.js:92 ~ tipEnabledList");
        });

        return response;
      } catch (error) {
        //console.log("Error during signin: ", error);//TODO debug
        throw error;
      }
    },
    setExpired(bool) {
      this.expired = bool;
      if (bool==true) this.loggedIN = false;
      //console.clear();
    },
    setHeaderTitel(titelText) {
      this.headerTitel = titelText;
    },
    setItems(items) {
      console.log("setItems");
      this.items = JSON.parse(JSON.stringify(items));
      //this.itemsLoaded = true;
    },
  },

  async refreshUserData() {
    try {
      await this.whoAmI(); // Lädt die Benutzerdaten neu
      await this.getCompanySettings(); // Lädt die Unternehmenseinstellungen neu
      console.log("Daten wurden erfolgreich neu geladen.");
    } catch (error) {
      console.error("Fehler beim Neuladen der Daten:", error);
    }
  },
  persist: true,
});
 */