import { defineAsyncComponent, markRaw, ref } from "vue";
import iAxios from "@/store/axiosInterface";
import download from 'downloadjs'
import { userStore } from "@/store/userStore";


const EmailEditDialog = defineAsyncComponent(() => import('@/views/EmailEditDialog.vue'));

const CatalogueDialog = defineAsyncComponent(() =>
    import("@/views/CatalogueDialog.vue")
  );
  const CatalogueDialogFooter = defineAsyncComponent(() =>
    import("@/views/CatalogueDialogFooter.vue")
  );
  const CatalogueDialogHeader = defineAsyncComponent(() =>
    import("@/views/CatalogueDialogHeader.vue")
  );
  
  let itemsForList = [];

  export const getCataloguePDF = (docData,dialog) => {
    generateItemList(docData); 
    openPDFCatalog(docData,dialog,false,"");
};
  export const getCataloguePDF4Email = (docData,dialog,openCatalogEmailDialog
  ) => {
    generateItemList(docData);
    openPDFCatalog(docData,dialog,openCatalogEmailDialog);
};

const generateItemList=(docData)=>{
    itemsForList = JSON.parse(JSON.stringify(docData.itemList));
    //alle item ausfiltern die item_id_company==null oder item_id_company==-1 ist
    itemsForList = itemsForList.filter((item) => item.item_id_company !== null && item.item_id_company !== -1);
}

const openPDFCatalog = (docData,dialog,openCatalogEmailDialog) => {
    const subHeader= "Katalog zu "+docData.docName;
    const forEmail = openCatalogEmailDialog?true:false;
    const dialogRef = dialog.open(CatalogueDialog, {
        props: {
            header: subHeader,
            style: {
                width: "40vw",
            },
            pt: { //workarround for breakpoints
                root: {
                  class: 'w-screen w-full md:w-5',
                },
              },
            breakpoints: {
                "331px": "100vw",
            },
            modal: true,
            closable: true,
        },
        data: {
            forEmail: forEmail,
            openCatalogEmailDialog:openCatalogEmailDialog,
            catalogSettings: {
                selectedVKStufe: "itemOutPrice",
                discountId: 0,
                publicItems: false,
                showVK: true,
                showImage: true,
                showCover: true,
                showBackground: true,
                showLogo: true,
                showAddress: true,
                showDiscount: true,
                Titel: "Katalog",
                subTitel: forEmail?'zu '+docData.docName:new Date().toLocaleDateString("de-DE", { day: '2-digit', month: '2-digit', year: 'numeric' }),
            },
            hideVk: true,
        },
        templates: {
            footer: markRaw(CatalogueDialogFooter),
            header: markRaw(CatalogueDialogHeader),
        },
        onClose: async (options) => {
            console.log("🚀 ~ file: catalogueUtils.js:81 ~ dialogRef.data.catalogSettings:", dialogRef.data.catalogSettings)
            if (options.data) {
                const buttonType = options.data.buttonType;
                if (buttonType == "Generate") {
                    generateCatalogue(dialogRef.data.catalogSettings,"download");
                }
                if (buttonType == "Sendemail") {
                 //   openCatalogEmailDialog(subHeader,options.data.settings,generateCatalogue);
                    openCatalogEmailDialog(subHeader,dialogRef.data.catalogSettings,generateCatalogue);
                }
            }
        }
    });
};

const generateCatalogueItemList = (settings,itemsList) => {
    if (itemsList) itemsForList=itemsList.value;
    const catalogueItems = [];

    for (let i = 0; i < itemsForList.length; i++) {
        const item = itemsForList[i];
        let vk = item.itemOutPrice;
        let rabatt = item?.itemParameters?.discount?.[settings?.discountId] || 0;
        if (item[settings?.selectedVKStufe]) {
            vk = item[settings.selectedVKStufe];
        }
        if (!settings?.publicItems && !item.itemActive) {
        } else {
            catalogueItems.push({
                fileHash: item.itemImageUrl?.[0]?.fileHash,
                EAN: item.EAN,
                item_id_company: item.item_id_company,
                itemCustomId: item.itemCustomId??item.item_id_company,
                itemName: item.itemName,
                itemAlias: item.itemAlias,
                VK: vk,
                UVP: item.UVP,
                discount: rabatt,
            });
        }
    }
    return catalogueItems;
};

export const generateCatalogFromItemView = (settings,proceedType,docData,itemsList,dialog) => {
    generateCatalogue(settings,proceedType,docData,itemsList,dialog);
}

 const generateCatalogue = (settings,proceedType,docData,itemsList,dialog) => {
    const isDownload = proceedType == "download";
    const isEmail = proceedType == "email";
    const body = {};
     if (settings?.selectedVKStufe) delete settings.selectedVKStufe;
    if (settings?.publicItems) delete settings.publicItems;
    body.settings = settings;
    body.itemList = generateCatalogueItemList(settings,itemsList);
    iAxios
        .post("/company/shipping-catalogue", body, {
            responseType: 'blob',
            headers: {
                "Content-Type": "application/json",
                Accept: "application/pdf",
            },
        })
        .then((response) => {
            if (isDownload){
            download(response.data, "Katalog.pdf", "application/pdf");
        } else if (isEmail){
            openCatalogEmailDialog("Katalog",settings,generateCatalogueItemList(settings,itemsList),dialog,docData);
        }
        else{
            const file = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            docData.data.pdfFile = fileURL;
           // return fileURL;
        }
        });
};
const emailDialogRef = ref(null);
const openCatalogEmailDialog = async (header,catalogSettings,itemsList,dialog,docData) => {
    const store = userStore();
    const docEmailSettings= store.companySettings.docEmailSettings.INVOICE;
    docEmailSettings.message = `Sehr geehrte Damen und Herren,
anbei erhalten Sie unseren Katalog

Mit freundlichen Grüßen
${store.whoAmIData.cname}
${store.whoAmIData.firstName} ${store.whoAmIData.surName}`;
emailDialogRef.value=dialog.open(EmailEditDialog, {
      props: {
        header: header,
        subHeader: "",
        style: {
          width: "65vw",
        },
        breakpoints: {
          "960px": "55vw",
          "640px": "65vw",
        },
        modal: true,
      },
      data: {
        showAddCustomer: true,
        docEmailSettings: store.companySettings.docEmailSettings.INVOICE,
        catalogSettings: catalogSettings,
        docType: "CATALOG",
        mainDocType: "CATALOG",
/*         customerUid: customer.customerUid,
 */        itemList: itemsList,
        docName:"Katalog",
        docTitel: "Katalog",
       /*  emailAddress:
          customer?.emailAddress != ""
            ? dialogRef.value.data.rechnungsDaten.receiver.emailAddress
            : await findCustomerEmail(
                dialogRef.value.data.rechnungsDaten.customerUid
              ), */
              previewCatalog:generateCatalogue,
        previewURL:
          "/company/shipping-catalogue",
           docData: docData,
      },
      onClose: async (options) => {
        console.log("🚀 ~ file: catalogueUtils.js:224 ~ options:", options)
      }
    });
  };